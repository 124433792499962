import styled from "styled-components";
import MDEditor from "@uiw/react-md-editor";
import { Form, Field } from "react-final-form";
import { useState, useEffect, useRef } from "react";
import { OnChange } from "react-final-form-listeners";
import { useActions } from "../hooks/useActions";
import { useNavigate, useParams } from "react-router-dom";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useSockets } from "../context/socket.context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface UserData {
  userData: {
    _id: string;
  };
}

const UploadNews: React.FC<UserData> = ({ userData: { _id } }) => {
  const { postNews, updateNewsDetails, searchIndvNews } = useActions();
  const { id } = useParams();
  const { data, indvData, error, loading } = useTypedSelector(
    (state) => state.newsReducer
  );
  const [err, setErr] = useState(false);
  const [reset, setReset] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [preview, setPreview] = useState(false);
  const navigate = useNavigate();
  const { socket } = useSockets();
  const [previewSource, setPreviewSource] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<FileReader>();
  const ref = useRef(null);
  const [newsData, setNewsData] = useState<any | undefined>();

  const required = (value: string) => (value ? undefined : "Required");
  useEffect(() => {
    if (id) {
      searchIndvNews(id);
    }
  }, []);

  useEffect(() => {
    if (id && !loading && indvData && indvData["title"] !== "") {
      setNewsData(indvData);
    }
  }, [newsData]);

  useEffect(() => {
    if (error !== null && err) {
      toast.error(
        "Seems like there was an error. Please check your fields and make sure they are unique!"
      );
      setLoader(false);
    } else if (
      ((data && data.length !== 0) || indvData?.title) &&
      err &&
      error === null
    ) {
      toast.success("News Posted,redirecting to dashboard..");
      setTimeout(() => {
        navigate("/dashboard");
        socket.emit("refreshDashboard");
      }, 4000);
    }
  }, [error, loading]);

  const previewFile = (file: any) => {
    if ((file && !id) || preview) {
      const reader = new FileReader();
      console.log(reader, "read");
      setSelectedFile(reader);
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPreviewSource(reader.result);
      };
    }
  };

  return (
    <div>
      <div style={{ cursor: 'pointer'}} className="fs-4 my-2 fw-bold d-flex align-items-center">
        <i onClick={() => navigate(-1)} className="fas fa-long-arrow-alt-left fs-2 ms-2"></i>
      </div>
      <Form
        initialValues={{
          title: id && indvData?.title,
          body: id && indvData?.body,
          imageCredits: id && indvData?.imageCredits,
          imageCover: id && indvData?.imageCover,
        }}
        onSubmit={({ title, body, imageCredits, imageCover }) => {
          if (id) {
            updateNewsDetails(
              id,
              title,
              body,
              imageCredits,
              imageCover,
              _id,
            );
          } else {
            postNews(
              title,
              body,
              imageCredits,
              _id,
              (imageCover = previewSource)
            );
          }
        }}
      >
        {({ handleSubmit, form, pristine }) => {
          return (
            <form
            //   ref={ref}
              onSubmit={(e) => {
                //   console.log(e)
                e.preventDefault();
                handleSubmit();
                setLoader(true);
            }}
            >
              <NewsPostCard>
                <NewsTitle>News</NewsTitle>
                <div className="image-details row">
                  <div className="left col-12 col-md-3 mb-3 d-flex justify-content-center">
                    <Field name="imageCover" validate={required}>
                      {({ meta, input: { value, onChange, ...input } }) => {
                        return (
                          <UserImageContainer>
                            {/* <InputBox> */}
                            <InputFileUpload
                              onChange={({ target }) =>
                                onChange(
                                  target && target.files && target.files[0]
                                )
                              }
                              {...input}
                              style={{ cursor: "pointer" }}
                              type="file"
                              name="imageCover"
                              className=""
                            />
                            <div className="image">
                              {previewSource ? (
                                <img
                                  src={previewSource}
                                  alt="chosen"
                                  // style={{ height: '300px' }}
                                />
                              ) : id && indvData?.imageCover ? (
                                <img
                                  src={indvData?.imageCover}
                                  alt={indvData?.title}
                                />
                              ) : (
                                <i className="fas fa-upload mt-5 fs-1"></i>
                              )}

                              {indvData?.imageCover ? (
                                <i className="fas fa-plus w-100  fs-4 text-end"></i>
                              ) : (
                                ""
                              )}
                            </div>
                            {meta.error && meta.touched && !previewSource && (
                              <span className="text-danger">{meta.error}</span>
                            )}
                            {/* </InputBox> */}
                          </UserImageContainer>
                        );
                      }}
                    </Field>
                    <OnChange name="imageCover">
                      {(value, previous) => {
                        if (value) {
                          setPreview(true);
                          previewFile(value);
                        }
                        if (!previous) {
                          previewFile(value);
                        }
                      }}
                    </OnChange>
                  </div>
                  <div className="right col-12 col-md-9">
                    <label htmlFor="title">News Title</label>
                    <Field name="title" validate={required}>
                      {({ input, meta }) => {
                        return (
                          <div>
                            <InputBox id="title" type="text" {...input} />
                            {meta.error && meta.touched && (
                              <span className="text-danger">{meta.error}</span>
                            )}
                          </div>
                        );
                      }}
                    </Field>
                    <label htmlFor="imageCredits">News Image Credits</label>
                    <Field name="imageCredits" validate={required}>
                      {({ input, meta }) => {
                        return (
                          <div>
                            <InputBox
                              id="imageCredits"
                              type="text"
                              {...input}
                            />
                            {meta.error && meta.touched && (
                              <span className="text-danger">{meta.error}</span>
                            )}
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                </div>

                <NewsTitle>Description</NewsTitle>
                <Field name="body">
                  {({ input, meta }) => {
                    return (
                      <div>
                        <MDEditor height={350} {...input} />
                        {meta.error && meta.touched && (
                          <span className="text-danger">{meta.error}</span>
                        )}
                      </div>
                    );
                  }}
                </Field>
              </NewsPostCard>
              <div className="buttons d-flex justify-content-end mb-5">
                <NewsButtons type="button" className="me-4">
                  Cancel
                </NewsButtons>
                <NewsButtons
                  type="submit"
                  disabled={pristine}
                  onClick={() => {
                    setErr(true);
                  }}
                >
                   {loader && !error ? 'Submitting...' : 'Submit'}
                </NewsButtons>
              </div>
            </form>
          );
        }}
      </Form>
      <ToastContainer />
    </div>
  );
};

export default UploadNews;

export const InputFileUpload = styled.input`
  position: absolute;
  width: 120px;
  height: 140px;
  opacity: 0;
  z-index: 2;
`;

export const UserImageContainer = styled.div`
  border-radius: 120px;
  width: 120px;
  height: 120px;
  opacity: 0.7;

  & img {
    border-radius: 120px;
    width: 120px;
    height: 120px;
  }

  & i {
    position: relative;
    /* position: absolute; */
    bottom: 0.5rem;
  }
`;

export const NewsPostCard = styled.div`
  background-color: #fff;
  border: 1px solid #dbe2e8;
  padding: 20px;
  margin-bottom: 20px;
`;

export const NewsTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5rem;
  color: "#2e3d49";
  /* margin-bottom: 1rem */
`;

export const InputBox = styled.input`
  color: #2e3d49;
  padding: 10px 5px;
  border: 1px solid #dbe2e8;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 0.875rem;
  height: 3rem;

  &:active,
  :focus {
    border: 1px solid var(--primary);
  }
`;

export const NewsButtons = styled.button`
  height: 3rem;
  padding: 0 1rem;
  background: var(--primary);
  border: transparent;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  letter-spacing: 1.5px;
  line-height: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
`;
