import styled from "styled-components";
import { useState, useEffect } from "react";
import AboutCard from "./AboutCard";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

const AboutCards: React.FC = () => {
  const { getAllUsers } = useActions();
  const { data, loading } = useTypedSelector((state) => state.allUsersReducer);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (!loading && data.length !== 0) {
      setAllUsers(data);
    }
  }, [data]);

  return (
    <div>
      <AboutCardList>
        <div className="row d-flex m-0 mt-5 justify-content-center justify-content-md-start">
          {allUsers
            .map((val, index) => {
              return (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 col-10 my-4 mt-md-0"
                  key={index}
                >
                  <AboutCard {...val} />
                </div>
              );
            })}
        </div>
      </AboutCardList>
      <div className="mb-5"></div>
    </div>
  );
};

export default AboutCards;

const AboutCardList = styled.div`
  margin-top: 4rem;
  @media (max-width: 577px) {
    margin-top: 0.5rem;
  }
`;
