import moment from "moment";
import { useEffect } from "react";
import styled from "styled-components";
import { useActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";

import "./applied-jobs-dashboard.css";

const AppliedJobsDashboard: React.FC = () => {
  const { searchApplyJob } = useActions();
  const { data } = useTypedSelector((state) => state.applyJobReducer);

  useEffect(() => {
    searchApplyJob();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div className="container">
    <div className="row">
        <div id="no-more-tables">
            <table className="col-md-12 table cf">
        		<thead className="cf">
        			<tr>
                    {/* <th scope="col">#</th> */}
                    <th scope="col">Email</th>
                    <th scope="col">Name</th>
                    <th scope="col">Why City?</th>
                    <th scope="col">Resume</th>
                    <th scope="col">Application Date</th>

        			</tr>
        		</thead>
        		<tbody>
                {data.map((val, i) => {
            return (
              <tr key={i}>
                <th scope="row" ><a href={`mailto:${val.email}`}>
                    <HighlightEmail className="highlight">
                      {val.email}
                    </HighlightEmail>
                  </a></th>
                
                <td data-title="Name">{val.name}</td>
                <td data-title="Why City?">{val.body}</td>
                <td data-title="Resume">
                  <a href={val.resume[0].data} target="_blank">
                  <HighlightResume className="highlight-resume">{val.resume[0].name.substring(0, 12)}...</HighlightResume>
                </a>
                </td>
                <td data-title="Application Date">{moment(val.createdAt).format("llll")}</td>
              </tr>
            );
          })}
        		</tbody>
        	</table>
        </div>
    </div>
</div>
  );
};

export default AppliedJobsDashboard;

export const HighlightEmail = styled.span`
  padding: 0.2em 13.7px;
  line-height: 1.8em;
  &.highlight {
    font-weight: bolder;
    background: linear-gradient(
        104deg,
        rgba(130, 255, 173, 0) 0.9%,
        rgba(130, 255, 173, 1.25) 2.4%,
        rgba(130, 255, 173, 0.5) 5.8%,
        rgba(130, 255, 173, 0.1) 93%,
        rgba(130, 255, 173, 0.7) 96%,
        rgba(130, 255, 1732, 0) 98%
      ),
      linear-gradient(
        183deg,
        rgba(130, 255, 173, 0) 0%,
        rgba(130, 255, 173, 0.3) 7.9%,
        rgba(130, 255, 173, 0) 15%
      );
    /* padding: 0.6em 13.7px; */
    margin: 0;
    border-radius: 7.5px;
    text-shadow: -12px 12px 9.8px rgba(130, 255, 173, 0.7),
      21px -18.1px 7.3px rgba(255, 255, 255, 1),
      -18.1px -27.3px 30px rgba(255, 255, 255, 1);
  }
`;

export const HighlightResume = styled.span`
  padding: 0.2em .7px;
  /* line-height: 1.8em; */
  &.highlight-resume {
    /* font-weight: bolder; */
    background: linear-gradient(
        104deg,
        rgba(130, 255, 173, 0) 0.9%,
        rgba(130, 255, 173, 1.25) 2.4%,
        rgba(130, 255, 173, 0.5) 5.8%,
        rgba(130, 255, 173, 0.1) 93%,
        rgba(130, 255, 173, 0.7) 96%,
        rgba(130, 255, 1732, 0) 98%
      ),
      linear-gradient(
        183deg,
        rgba(130, 255, 173, 0) 0%,
        rgba(130, 255, 173, 0.3) 7.9%,
        rgba(130, 255, 173, 0) 15%
      );
    /* padding: 0.6em 13.7px; */
    margin: 0;
    border-radius: 7.5px;
    text-shadow: -12px 12px 9.8px rgba(130, 255, 173, 0.7),
      21px -18.1px 7.3px rgba(255, 255, 255, 1),
      -18.1px -27.3px 30px rgba(255, 255, 255, 1);
  }
`;