import produce from 'immer';
import { ActionType } from '../action-types';
import { Action, UserData } from '../actions'

// type Tag = { title: string highlight: boolean; };
interface UserState {
    loading: Boolean;
    error: string | null;
    data: UserData
}

const initialState = {
    loading: false,
    error: null,
    data: {
        role: 'student',
        email: '',
        name: '',
        _id: '',
        about: '',
        skills: [],
        expertise: [],
        hobbies: [],
        languages: [],
        onTheWeb: [''],
        photos: [''],
        profileImage: '',
        news: [], 
        lifePhil: '',
        attachments: []
    }
};

const reducer = produce((state: any = initialState, action: Action): UserState => {
    switch(action.type) {
        case ActionType.CURRENT_USER:
            return { loading: true, error: null, data: initialState.data };
        case ActionType.CURRENT_USER_SUCCESS:
            return { loading: false, error: null, data: action.payload}
        case ActionType.CURRENT_USER_ERROR:
            return { loading: false, error: action.payload, data: initialState.data }
        case ActionType.UPDATE_USER:
            return { loading: true, error: null, data: initialState.data };
        case ActionType.UPDATE_USER_SUCCESS:
            return { loading: false, error: null, data: action.payload}
        default:
            return state;
    }
})

export default reducer;