import { useEffect } from "react";
import styled from "styled-components";
import { useActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";

const EducationWorkshop: React.FC = () => {
  const { searchContactUs } = useActions();
  const { data, loading } = useTypedSelector((state) => state.contactUsReducer);

  useEffect(() => {
    searchContactUs();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Message</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          {data.map((val, i) => {
            return (
              <tr  key={i}>
                <th scope="row">{i + 1}</th>
                <td>{val.name}</td>
                <td>{val.body}</td>
                <td>
                  <a href={`mailto:${val.email}`}>
                    <HighlightEmail className="highlight">
                      {val.email}
                    </HighlightEmail>
                  </a>
                </td>
              </tr>
            );
          })}
          {/* <tr>
            <th scope="row">1</th>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default EducationWorkshop;

export const HighlightEmail = styled.span`
  padding: 0.2em 13.7px;
  line-height: 1.8em;
  &.highlight {
    font-weight: bolder;
    background: linear-gradient(
        104deg,
        rgba(130, 255, 173, 0) 0.9%,
        rgba(130, 255, 173, 1.25) 2.4%,
        rgba(130, 255, 173, 0.5) 5.8%,
        rgba(130, 255, 173, 0.1) 93%,
        rgba(130, 255, 173, 0.7) 96%,
        rgba(130, 255, 1732, 0) 98%
      ),
      linear-gradient(
        183deg,
        rgba(130, 255, 173, 0) 0%,
        rgba(130, 255, 173, 0.3) 7.9%,
        rgba(130, 255, 173, 0) 15%
      );
    /* padding: 0.6em 13.7px; */
    margin: 0;
    border-radius: 7.5px;
    text-shadow: -12px 12px 9.8px rgba(130, 255, 173, 0.7),
      21px -18.1px 7.3px rgba(255, 255, 255, 1),
      -18.1px -27.3px 30px rgba(255, 255, 255, 1);
  }
`;
