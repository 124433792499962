import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { useEffect, useState } from "react";
import TagsInput from "../components/TagsInput";
import { OnChange } from "react-final-form-listeners";
import { useActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { ToastContainer, toast } from "react-toastify";
import { useSockets } from "../context/socket.context";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

interface Tag {
  title: string;
  highlight: boolean;
}
interface UserData {
  userData: {
    role: string;
    email: string;
    name: string;
    _id: string;
    about: string;
    profileImage: string;
    skills: { title: string; highlight: boolean }[];
    expertise: { title: string; highlight: boolean }[];
    hobbies: { title: string; highlight: boolean }[];
    languages: { title: string; highlight: boolean }[];
    linkedin: string;
    facebook: string;
    twitter:string;
    instagram: string;
    link: string;
    photos: string[];
    attachments?: any[];
    lifePhil?: string;
  };
}

const UpdateUser: React.FC<UserData> = ({ userData }) => {
  const [selectedTag, setTags] = useState<[]>();
  const [expertiseTag, setExpertiseTags] = useState();
  const [hobbiesTag, setHobbiesTags] = useState();
  const [languagesTag, setLanguagesTags] = useState();
  const [preview, setPreview] = useState(false);
  const [att, setAtt] = useState<any[]>();
  const [fileInputState, setFileInputState] = useState(null);
  const [previewSource, setPreviewSource] = useState<any>("");
  const [linkedin, setLinkedIn] = useState<any>("");
  const [facebook, setFacebook] = useState<any>("");
  const [instagram, setInstagram] = useState<any>("");
  const [twitter, setTwitter] = useState<any>("");
  const [link, setLink] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<FileReader>();
  const { updateUserDetails } = useActions();
  const navigate = useNavigate();
  const { data, error, loading } = useTypedSelector(
    (state) => state.userReducer
  );
  const { socket } = useSockets();
  const [err, setErr] = useState(false);
  let accumulator: any = [];
  let expertiseAcc: any = [];
  let hobbiesAcc: any = [];
  let languageAcc: any = [];


  const selectedTags = (tags: Tag) => {
    accumulator = accumulator.concat(tags);

    setTags(accumulator);
  };
  const expertiseTags = (expertiseTag: Tag) => {
    expertiseAcc = expertiseAcc.concat(expertiseTag);
    setExpertiseTags(expertiseAcc);
  };

  const hobbiesTags = (hobbiesTag: Tag) => {
    hobbiesAcc = hobbiesAcc.concat(hobbiesTag);
    setHobbiesTags(hobbiesAcc);
  };

  const languagesTags = (languageTag: Tag) => {
    // console.log(languageTag);
    languageAcc = languageAcc.concat(languageTag);
    setLanguagesTags(languageAcc);
  };

  const onKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  let [attachments_, setAttachments] = useState<any>([]);

  useEffect(() => {
    if (error !== null && err) {
      toast.error("Seems like there was an error.");
    } else if (err && error === null) {
      toast.success("Profile updated!");
      setTimeout(() => {
        socket.emit("refreshDashboard");
      }, 2000);
    }
  }, [err]);


  function readmultifiles(e: any) {
    // console.log(e.currentTarget)
    const files = e.currentTarget.files;
    console.log('files', files)

    Object.keys(files).forEach((i) => {
      const file = files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        // console.log(reader.fileName);
        setAttachments((oldAttachment: any) => [
          ...(userData.attachments ?? []),
          {
            name: file.name,
            data: reader.result,
          },
        ]);
        //server call for uploading or reading the files one-by-one
        //by using 'reader.result' or 'file'
      };
    });
  }

  const previewFile = (file: any) => {
    if (typeof file !== "string") {
      const reader = new FileReader();
      setSelectedFile(reader);
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPreviewSource(reader.result);
      };
    }
  };

  useEffect(() => {
    // console.log('j', userData &&userData,attachments)
    setAttachments(userData.attachments)
  },[data])

  return (
    <div className="update-user">
      <div style={{ cursor: 'pointer'}} className="fs-4 my-2 fw-bold d-flex align-items-center">
        <i onClick={() => navigate(-1)} className="fas fa-long-arrow-alt-left fs-2 ms-2"></i>
      </div>
      <div className="update-user-container">
        <Form
          initialValues={{
            displayName: userData && userData.name,
            about: userData && userData.about,
            profileImage: userData && userData.profileImage,
            linkedin: userData && userData.linkedin,
            twitter: userData && userData.twitter,
            instagram: userData && userData.instagram,
            facebook: userData && userData.facebook,
            link: userData && userData.link,
            // skills: userData && userData.skills,
            // expertise: userData && userData.expertise,
            // languages: userData && userData.languages,
            lifePhil: userData && userData.lifePhil,
            attachments: userData && userData.attachments,
          }}
          onSubmit={({
            displayName,
            about,
            skills = selectedTag,
            expertise = expertiseTag,
            hobbies = hobbiesTag,
            languages = languagesTag,
            lifePhil,
            linkedin,
            facebook,
            twitter,
            link,
            instagram,
            profileImage = previewSource,
            attachments = attachments_ || userData.attachments,

          }) => {
            // console.log('l', languages)
            updateUserDetails(
              displayName,
              about,
              skills,
              expertise,
              hobbies,
              languages,
              lifePhil,
              linkedin, 
              twitter, 
              link, 
              instagram, 
              facebook,
              (profileImage = previewSource || userData.profileImage),
              attachments = attachments_ || userData.attachments,
            );
          }}
        >
          {({ handleSubmit }) => {
            return (
              <form
                onKeyPress={onKeyPress}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <UserDetailsCard>
                  <UserTitle>Basic Information</UserTitle>
                  <div className="image-details row">
                    <div className="left col-12 col-md-3 mb-3 d-flex justify-content-center">
                      <Field name="profileImage">
                        {({ input: { value, onChange, ...input } }) => {
                          return (
                            <UserImageContainer>
                              {/* <InputBox> */}
                              <InputFileUpload
                                onChange={({ target }) =>
                                  onChange(
                                    target && target.files && target.files[0]
                                  )
                                }
                                {...input}
                                style={{ cursor: "pointer" }}
                                type="file"
                                name="profileImage"
                                className=""
                              />
                              <div className="image">
                                {previewSource ? (
                                  <img
                                    src={previewSource}
                                    alt="chosen"
                                    // style={{ height: '300px' }}
                                  />
                                ) : userData && userData?.profileImage ? (
                                  <img
                                    src={userData?.profileImage}
                                    alt={userData?.name}
                                  />
                                ) : (
                                  <i className="fas fa-upload mt-5 fs-1"></i>
                                )}

                                {userData?.profileImage ? (
                                  <i className="fas fa-plus w-100  fs-4 text-end"></i>
                                ) : (
                                  ""
                                )}
                              </div>
                              {/* </InputBox> */}
                            </UserImageContainer>
                          );
                        }}
                      </Field>
                      <OnChange name="profileImage">
                        {(value, previous) => {
                          if (value && previous) {
                            setPreview(true);
                            previewFile(value);
                          }
                          if (previous === "") {
                            previewFile(value);
                          }
                        }}
                      </OnChange>
                    </div>
                    <div className="right col-12 col-md-9">
                      <Field name="displayName">
                        {({ input }) => {
                          return <InputBox id="name" type="text" {...input} />;
                        }}
                      </Field>
                      <Field name="email">
                        {({ input }) => {
                          return (
                            <InputBox
                              {...input}
                              disabled
                              type="email"
                              className="mt-3"
                              value={userData.email}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>

                  <UserTitle className="mb-0">About</UserTitle>
                  <Field name="about">
                    {({ input }) => {
                      return <TextareaBox {...input} />;
                    }}
                  </Field>
                  <UserTitle className="mb-0">Life Philosophy</UserTitle>
                  <Field name="lifePhil">
                    {({ input }) => {
                      return (
                        <InputBox
                          {...input}
                          type="text"
                          className="mt-3"
                        />
                      );
                    }}
                  </Field>
                  <UserTitle className="mb-0">On the web (enter usernames only. For portfolio please enter full URL)</UserTitle>
                  <div className="d-flex align-items-center justify-content-start">
                    <i className="fab fa-linkedin mx-2"></i>
                    <Field name="linkedin">
                    {({ input }) => {
                      return (
                        <InputBox
                          {...input}
                          type="text"
                          style={{ width: '8rem', height: '1rem' }}
                          className=""
                        />
                      );
                    }}
                  </Field>
                  <i className="fab fa-facebook mx-2"></i>
                    <Field name="facebook">
                    {({ input }) => {
                      return (
                        <InputBox
                          {...input}
                          type="text"
                          style={{ width: '8rem', height: '1rem' }}
                          className=""
                        />
                      );
                    }}
                  </Field>
                  <i className="fab fa-instagram mx-2"></i>
                    <Field name="instagram">
                    {({ input }) => {
                      return (
                        <InputBox
                          {...input}
                          type="text"
                          style={{ width: '8rem', height: '1rem' }}
                          className=""
                        />
                      );
                    }}
                  </Field>
                  <i className="fab fa-twitter mx-2"></i>
                    <Field name="twitter">
                    {({ input }) => {
                      return (
                        <InputBox
                          {...input}
                          type="text"
                          style={{ width: '8rem', height: '1rem' }}
                          className=""
                        />
                      );
                    }}
                  </Field>
                  <i className="fas fa-link mx-2"></i>
                    <Field name="link">
                    {({ input }) => {
                      return (
                        <InputBox
                          {...input}
                          type="text"
                          style={{ width: '8rem', height: '1rem' }}
                          className=""
                        />
                      );
                    }}
                  </Field>
                  
                  </div>
                  <UserTitle className="mb-0">Skills</UserTitle>
                  <Field name="skills">
                    {() => {
                      return (
                        <TagsInput
                          selectedTags={selectedTags}
                          tags={userData.skills}
                        />
                      );
                    }}
                  </Field>
                  <UserTitle className="mb-0">Expertise</UserTitle>
                  <Field name="expertise">
                    {() => {
                      return (
                        <TagsInput
                          selectedTags={expertiseTags}
                          tags={userData.expertise}
                        />
                      );
                    }}
                  </Field>
                  <UserTitle className="mb-0">Hobbies</UserTitle>
                  <Field name="hobbies">
                    {() => {
                      return (
                        <TagsInput
                          selectedTags={hobbiesTags}
                          tags={userData.hobbies}
                        />
                      );
                    }}
                  </Field>
                  <UserTitle className="mb-0">Languages</UserTitle>
                  <Field name="languages">
                    {() => {
                      return (
                        <TagsInput
                          selectedTags={languagesTags}
                          tags={userData.languages}
                        />
                      );
                    }}
                  </Field>
                  <UserTitle className="mb-0">Attachments</UserTitle>
                  {userData && userData.attachments && userData.attachments.map((data: any, i: number) => {
                    return (
                      <span
                        style={{ position: "relative" }}
                        className="me-2"
                        key={i}
                      >
                        <i
                          onClick={() =>
                        attachments_ &&   attachments_.filter((val: any, index: number) => data.name !== val.name)
                          }
                          style={{ position: "absolute", top: "-.6rem" }}
                          className="fas fa-times small"
                        ></i>
                        <span>{data.name}</span>
                      </span>
                    );
                  })}
                  <Field name="attachments">
                    {({ input: { value, onChange, ...input } }) => {
                      return (
                        <InputBox
                          accept="application/pdf"
                          onChange={(e) => readmultifiles(e)}
                          id="attachments"
                          multiple
                          type="file"
                          {...input}
                        />
                      );
                    }}
                    {/* {userData && userData.attachments } */}
                  </Field>
                
                </UserDetailsCard>
                <div className="buttons d-flex justify-content-end mb-5">
                  <UserButtons type="button" className="me-4">
                    Cancel
                  </UserButtons>
                  <UserButtons
                    onClick={() => {
                      setErr(true);
                    }}
                    type="submit"
                  >
                    Save
                  </UserButtons>
                </div>
              </form>
            );
          }}
        </Form>
        <ToastContainer />
      </div>
      <div className="my-5">
        <hr />
      </div>
    </div>
  );
};

export default UpdateUser;

export const InputFileUpload = styled.input`
  position: absolute;
  width: 120px;
  height: 140px;
  opacity: 0;
  z-index: 2;
`;

export const UserDetailsCard = styled.div`
  background-color: #fff;
  border: 1px solid #dbe2e8;
  padding: 20px;
  margin-bottom: 20px;
`;

export const UserImageContainer = styled.div`
  border-radius: 120px;
  width: 120px;
  height: 120px;
  opacity: 0.7;

  & img {
    border-radius: 120px;
    width: 120px;
    height: 120px;
  }

  & i {
    position: relative;
    /* position: absolute; */
    bottom: 0.5rem;
  }
`;

export const UserButtons = styled.button`
  height: 3rem;
  padding: 0 1rem;
  background: var(--primary);
  border: transparent;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  letter-spacing: 1.5px;
  line-height: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
`;

export const UserTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5rem;
  color: "#2e3d49";
  margin-bottom: 1rem;
`;

export const InputBox = styled.input`
  color: #2e3d49;
  padding: 10px 5px;
  border: 1px solid #dbe2e8;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 0.875rem;
  height: 3rem;

  &:active,
  :focus {
    border: 1px solid var(--primary);
  }
`;
export const TextareaBox = styled.textarea`
  color: #2e3d49;
  padding: 10px 5px;
  border: 1px solid #dbe2e8;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 0.875rem;
  min-height: 10rem;
  max-height: 15rem;
  overflow-y: scroll;
`;
