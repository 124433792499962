import { useState } from 'react';
import styled from 'styled-components';
import ContactPopup from './ContactPopup';

const ContactWidget: React.FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <WidgetContainer className='d-flex justify-content-end mb-5'>
      <Widget onClick={() => setOpen(!open)}>
        <WidgetIcon className='d-flex justify-content-center align-items-center'>
          <i
            className={`fas fa-${
              open ? 'times fs-4' : 'comment-alt fs-4 '
            } text-white`}
          ></i>
        </WidgetIcon>
      </Widget>
      {open ? (
        <ContactBox className='position-fixed'>
          <ContactPopup popup={(data) => setOpen(data)} />
        </ContactBox>
      ) : (
        ''
      )}
    </WidgetContainer>
  );
};

export default ContactWidget;

export const WidgetContainer = styled.div`
  position: fixed;
  right: 4rem;
  bottom: 2rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  z-index: 100;
`;

export const Widget = styled.div`
  height: 4rem;
  width: 4rem;
  background-color: var(--primary);
  border-radius: 50%;
  cursor: pointer;
  margin-top: 1.5rem;
`;

export const WidgetIcon = styled.div`
  position: relative;
  top: 1.35rem;
`;

export const ContactBox = styled.div`
  position: absolute;
  transform: translateY(-100%);
  margin-bottom: 0.75rem;
`;
