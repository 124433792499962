import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LoginButton } from '../GlobalStyles';
import { useActions } from '../hooks/useActions';

interface FooterProps {
  userData: {
    role: string;
    email: string;
    name: string;
    _id: string;
    news?: any[];
  };
}

const Footer: React.FC<FooterProps> = ({ userData }) => {
  const { currentUserData } = useActions();

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <FooterContainer>
      <CopyrightText>
        © 2021 Maintained by The Center for Sustainability
      </CopyrightText>
      <ButtonContainer>
        <StyledLoginButton
          onClick={() =>
            openInNewTab(
              'https://docs.google.com/document/d/1EGFz1ianCWcH1esYZl6SFv4D7hNinhpDWpyZYiIUPnY/edit'
            )
          }
        >
          Visit Us
        </StyledLoginButton>
        <StyledLoginButton
          onClick={() =>
            openInNewTab(
              'https://giving.umd.edu/giving/fund.php?name=cityumd-gift-fund'
            )
          }
        >
          Donate
        </StyledLoginButton>
        <StyledLoginButton
          onClick={() => userData && userData._id && currentUserData()}
        >
          <Link
            className='text-white'
            to={userData && userData._id ? '/dashboard' : '/login'}
          >
            {userData && userData._id ? 'Dashboard' : 'Login'}
          </Link>
        </StyledLoginButton>
      </ButtonContainer>
    </FooterContainer>
  );
};

export default Footer;

// Styled Components
const FooterContainer = styled.div`
  background-color: #f9f9f9;
  margin: auto;
  bottom: 0;
  width: 100%;
  z-index: 100;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 3rem; // Set a fixed height

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: center;
    /* padding: 0.5rem 1rem; // Adjust padding for larger screens */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-top: 1rem; */

  @media (min-width: 992px) {
    margin-top: 0;
    margin-left: 2rem;
  }
`;

const StyledLoginButton = styled(LoginButton)`
  height: 1.5rem;
  /* margin-top: 1rem; */
  margin-left: 1rem; // Added for consistent spacing between buttons

  @media (min-width: 992px) {
    margin-top: 0;
    margin-left: 1rem;
  }
`;

const CopyrightText = styled.div`
  text-align: center;
  font-size: 0.9rem;
  color: #666;

  @media (max-width: 992px) {
    display: none; // Hide on smaller screens
  }
`;
