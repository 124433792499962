import { ActionType } from '../action-types';
import { Action } from '../actions'

interface LoginState {
    loading: boolean;
    error: string | null;
    data: {
        role: string,
        email: string,
        name: string,
        _id: string
    }
}

const initialState = {
    loading: false,
    error: null,
    data: {
        role: 'student',
        email: '',
        name: '',
        _id: ''
    }
};

const reducer = (state: LoginState = initialState, action: Action): LoginState => {
    switch(action.type) {
        case ActionType.LOGIN:
            return { ...initialState, loading: true }
        case ActionType.LOGOUT:
            return { ...initialState, loading: true }
        case ActionType.LOGIN_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.LOGOUT_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.LOGIN_ERROR:
            return { ...initialState, error: action.payload }
        case ActionType.LOGOUT_ERROR:
            return { ...initialState, error: action.payload }
        default:
            return state;
    }
}

export default reducer;