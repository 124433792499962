import { ActionType } from '../action-types';
import { Action } from '../actions';

interface ContactUsState {
    loading: boolean;
    error: string | null;
    data: any[];
}

const initialState = {
    loading: false,
    error: null,
    data: []
}

const reducer = (state: ContactUsState = initialState, action: Action): ContactUsState => {
    switch (action.type) {
        case ActionType.POST_CONTACTUS:
            return { loading: true, error: null, data: [] }
        case ActionType.POST_CONTACTUS_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.POST_CONTACTUS_ERROR:
            return { loading: false, error: action.payload, data: [] }
        case ActionType.SEARCH_CONTACTUS:
            return { loading: true, error: null, data: [] }
        case ActionType.SEARCH_CONTACTUS_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.SEARCH_CONTACTUS_ERROR:
            return { loading: false, error: action.payload, data: [] }
        default:
            return state;
    };
};

export default reducer;
