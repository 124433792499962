// src/routes/AppRoutes.tsx

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AboutCards from './../components/AboutCards/AboutCards';
import { AnimatePresence } from 'framer-motion';
import DashboardNewsList from '../components/dashboard/DashboardNewsList';
import SeeAllDashboard from '../pages/SeeAllDashboard';
import SeeAllDashboardJobs from '../pages/SeeAllDashboardJobs';
import ChangePassword from '../pages/ChangePassword';
import UpdateUser from '../pages/UpdateUser';
import UserInfo from '../pages/UserInfo';
import UpdateProject from '../pages/UpdateProject';
import UpdateWorkshop from '../pages/UpdateWorkshop';
import UpdateEducation from '../pages/UpdateEducation';
import UpdatePublication from '../pages/UpdatePublication';
import UpdateJob from '../pages/UpdateJob';
import UpdateNotes from '../pages/UpdateNotes';
import UploadNews from '../pages/UploadNews';
import AppliedJobsDashboard from '../pages/AppliedJobsDashboard';
import EducationWorkshop from '../pages/EducationWorkshop';
import AllUsers from '../pages/AllUsers';
import SeeAllDashboardNotes from '../pages/SeeAllDashboardNotes';
import SeeAllDashboardPublications from '../pages/SeeAllDashboardPublications';
import SeeAllDashboardProjects from '../pages/SeeAllDashboardProject';
import SeeAllDashboardWorkshops from '../pages/SeeAllDashboardWorkshops';
import SeeAllDashboardEducation from '../pages/SeeAllDashboardEducation';
// Dynamic imports for route components using React.lazy
const ProjectCardList = React.lazy(
  () => import('../components/projectCards/ProjectCardList')
);
const Report = React.lazy(() => import('../components/projectCards/Report'));
const PublicationCardList = React.lazy(
  () => import('../components/publicationCards/PublicationCardList')
);

const AboutUser = React.lazy(() => import('../pages/AboutUser'));
const NewsCards = React.lazy(() => import('../components/News/NewsCards'));
const IndvNews = React.lazy(() => import('../pages/news/IndvNews'));

const Landing = React.lazy(() => import('../pages/Landing'));
const About = React.lazy(() => import('../pages/About'));
const News = React.lazy(() => import('../pages/news/News'));
const Projects = React.lazy(() => import('../pages/Projects'));
const Software = React.lazy(() => import('../pages/Software'));
const Login = React.lazy(() => import('../pages/Login'));
const Publications = React.lazy(() => import('../pages/Publications'));
const Search = React.lazy(() => import('../pages/Search'));
const ApplyJob = React.lazy(() => import('../pages/ApplyJob'));
const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const Sponsors = React.lazy(() => import('../pages/Sponsors'));
const Jobs = React.lazy(() => import('../pages/Jobs'));
const Education = React.lazy(() => import('../pages/Education'));

// interface userDataProps {
//   role: string;
//   email: string;
//   name: string;
//   _id: string;
//   news?: any[];
//   projects?: any[];
//   publications?: any[];
//   education?: any[];
//   workshops?: any[];
//   jobs?: any[];
//   notes?: any[];
// }

const AppRoutes = ({ userData }: { userData: any }) => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <AnimatePresence>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/team' element={<About />}>
            <Route index element={<AboutCards />} />
            <Route path=':id' element={<AboutUser />} />
          </Route>
          <Route path='/news' element={<News />}>
            <Route index element={<NewsCards />} />
            <Route path=':id' element={<IndvNews />} />
          </Route>
          <Route path='/projects' element={<Projects />}>
            <Route index element={<ProjectCardList />} />
            <Route path='reports' element={<Report />} />
          </Route>
          <Route path='/software' element={<Software />} />
          <Route path='/login' element={<Login />} />
          <Route path='/publications' element={<Publications />}>
            <Route index element={<PublicationCardList />} />
          </Route>
          <Route path='/search' element={<Search />} />
          <Route path='/apply/:id' element={<ApplyJob />} />
          <Route path='/dashboard' element={<Dashboard userData={userData} />}>
            <Route index element={<DashboardNewsList userData={userData} />} />
            <Route
              path='all/news'
              element={<SeeAllDashboard userData={userData} />}
            />
            <Route
              path='all/education'
              element={<SeeAllDashboardEducation userData={userData} />}
            />
            <Route
              path='all/workshops'
              element={<SeeAllDashboardWorkshops userData={userData} />}
            />
            <Route
              path='all/projects'
              element={<SeeAllDashboardProjects userData={userData} />}
            />
            <Route
              path='all/publications'
              element={<SeeAllDashboardPublications userData={userData} />}
            />
            <Route
              path='all/notes'
              element={<SeeAllDashboardNotes userData={userData} />}
            />
            <Route
              path='all/jobs'
              element={<SeeAllDashboardJobs userData={userData} />}
            />
            <Route path='all-users' element={<AllUsers />} />
            <Route path='education-workshop' element={<EducationWorkshop />} />
            <Route
              path='applied-jobs-list'
              element={<AppliedJobsDashboard />}
            />
            <Route
              path='upload-news'
              element={<UploadNews userData={{ _id: userData._id }} />}
            />
            <Route
              path='upload-news/:id'
              element={<UploadNews userData={{ _id: userData._id }} />}
            />
            <Route
              path='update-job'
              element={<UpdateJob userData={{ _id: userData._id }} />}
            />
            <Route
              path='update-notes'
              element={<UpdateNotes userData={{ _id: userData._id }} />}
            />
            <Route
              path='update-notes/:id'
              element={<UpdateNotes userData={{ _id: userData._id }} />}
            />
            <Route
              path='update-job/:id'
              element={<UpdateJob userData={{ _id: userData._id }} />}
            />
            <Route
              path='update-publication'
              element={<UpdatePublication userData={userData} />}
            />
            <Route
              path='update-publication/:id'
              element={<UpdatePublication userData={{ _id: userData._id }} />}
            />
            <Route
              path='update-project'
              element={<UpdateProject userData={userData} />}
            />
            <Route
              path='update-education'
              element={<UpdateEducation userData={userData} />}
            />
            <Route
              path='update-education/:id'
              element={<UpdateEducation userData={userData} />}
            />
            <Route
              path='update-workshop'
              element={<UpdateWorkshop userData={userData} />}
            />
            <Route
              path='update-workshop/:id'
              element={<UpdateWorkshop userData={userData} />}
            />

            <Route
              path='update-project/:id'
              element={<UpdateProject userData={{ _id: userData._id }} />}
            />
          </Route>

          <Route path='/dashboard/update-user' element={<UserInfo />}>
            <Route index element={<UpdateUser userData={userData} />} />
            <Route
              path='change-password'
              element={<ChangePassword userData={{ _id: userData._id }} />}
            />
            {/* <Route path="/attachments" element={<Attachments />} /> */}
          </Route>
          <Route path='/sponsors' element={<Sponsors />} />
          <Route path='/jobs' element={<Jobs />} />
          <Route path='/education' element={<Education />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </AnimatePresence>
    </React.Suspense>
  );
};

export default AppRoutes;
