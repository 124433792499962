import { useMatch, useResolvedPath } from 'react-router-dom';

export function useIsActiveLink(path: string) {
  const resolvedPath = useResolvedPath(path);
  const match = useMatch({ path: resolvedPath.pathname, end: true });

  return match
    ? 'active bg-light text-dark mx-md-0'
    : 'bg-light text-dark mx-md-0';
}
