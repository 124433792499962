import { useEffect, useState } from "react";
import styled from "styled-components";
import { UtilsButton } from "../GlobalStyles";
import './tags-input.css';


interface Tag {
    title: string; 
    highlight: boolean; 
}
interface TagsInputProps {
    tags: Tag[];
	name?: string;
    selectedTags: (data: { title: string, highlight: boolean }) => void;
	
}

const TagsInput: React.FC<TagsInputProps> = ({tags, selectedTags, name}) => {
	const [tag, setTags] = useState(tags);
	const [highlight, setHighlight] = useState<boolean>(false);

	useEffect(() => {
		if (tags === undefined) {
			setTags([]);
		}

		if(tags && tags.length > 0) {
			setTags(tags);
		}
	}, [tags]);

	useEffect(() => {
		tag.map((t) => {
			return selectedTags({
				title: t.title,
				highlight: t.highlight,
			})
		})
	},[tag, highlight])

	const removeTags = (indexToRemove: number) => {
		if([...tag].length === 1) {
			return setTags([{ title: "Add", highlight: false}])
		}
		return setTags([...tag.filter((_, index) => index !== indexToRemove)])
	};

	const highlightTags = (titleToHighlight: string) => {
		tag.map((val, i) => {
			if(val.title === titleToHighlight) {
				setHighlight(!highlight)
				tag[i] = {
					title: val.title,
					highlight: !val.highlight,
				}
			}
		})
	}

	const addTags = (event: any) => {
		if (event.target.value !== "") {
			setTags([...tag.filter((val, _) => val.title !== 'Add') , {
				title: event.target.value,
				highlight: false
			}]);
			event.target.value = ""
		}
		
	};


	return (
		<div className="tags-input">
			<ul id="tags" className="d-flex p-0 m-0">
				{tag.map((tag, index) => (
					<li key={index} className="tag flex-column">
						<div style={{ position: "relative"}} className="d-flex justify-content-between w-100">
						<span style={{ color:  tag.highlight ? 'var(--secondary)' : 'black', cursor: 'pointer'}} className='fs-6'
						 onClick={() => highlightTags( tag.title)} 
						>
							<i className="fas fa-star ms-2"></i>
						</span>
						<span  className='tag-close-icon fs-6 text-dark'
							onClick={() => removeTags(index)}
						>
							<div className="">
								<i className="fas fa-times me-2"></i>
							</div>
							
						</span>
						</div>
						<UtilsButton style={{ lineHeight: "1rem", height: '2rem', padding: '0 0.5rem', textTransform: "none"}} className='tag-title mx-1 fs-6 mb'>
							<span className="mb-1">{tag.title}</span>
						</UtilsButton>
						
					</li>
				))}
			</ul>
			<InputBoxTags
				type="text"
				onKeyUp={e => {
					if (e.key === 'Enter') {
						e.preventDefault();
						return addTags(e)
					} else {
						return null;
					}
				}}
				placeholder="Press enter to add tags"
			/>
		</div>
	);
};

export default TagsInput;

export const InputBoxTags = styled.input`
    color: #2e3d49;
    padding: 10px 5px;
    border: 1px solid #dbe2e8;
    border-radius: 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    font-size: .875rem;
    height: 3rem;
`