import DashboardNewsCard from './DashboardNewsCard';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { UtilsButton } from '../../GlobalStyles';
import DashboardGeneralCard from './DashboardGeneralCard';
import DashboardProjectCard from './DashboardProjectCard';
import DashboardPublicationCard from './DashboardPublicationCard';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useEffect, useState } from 'react';
import DashboardEducationCard from './DashboardEducationCard';
import DashboardWorkshopCard from './DashboardWorkshopCard';
import DashboardJobCard from './DashboardJobCard';
import DashboardNotesCard from './DashboardNotesCard';

interface userData {
  userData: {
    role: string;
    email: string;
    name: string;
    _id: string;
    news?: any[];
    projects?: any[];
    publications?: any[];
    education?: any[];
    workshops?: any[];
    jobs?: any[];
    notes?: any[];
  };
}

const DashboardNewsList: React.FC<userData> = ({ userData }) => {
  const { getAllUsers, searchContactUs } = useActions();
  const { data, loading } = useTypedSelector((state) => state.allUsersReducer);
  const { data: contactData } = useTypedSelector(
    (state) => state.contactUsReducer
  );
  const { searchApplyJob } = useActions();
  const { data: jobSearchData } = useTypedSelector(
    (state) => state.applyJobReducer
  );

  useEffect(() => {
    searchApplyJob();
  }, []);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [allContact, setAllContact] = useState<any[]>([]);
  const [allJobsSearch, setAllJobsSearchContact] = useState<any[]>([]);
  useEffect(() => {
    getAllUsers();
    searchContactUs();
  }, []);

  useEffect(() => {
    if (!loading && data.length !== 0) {
      setAllUsers(data);
    }
    setAllJobsSearchContact(jobSearchData);
    setAllContact(contactData);
  }, [data, contactData]);

  return (
    <DashboardMainContainer className=' mx-2 ' style={{ height: '85vh' }}>
      {userData.role === 'admin' && (
        <DashboardGeneralContainer className='row d-flex m-0 justify-content-center justify-content-md-start'>
          <div className='row'>
            {[
              {
                image: 'all_students.png',
                total: allUsers.length,
                to: '/dashboard/all-users',
              },
              {
                image: 'contacted_us.png',
                total: allContact.length,
                to: '/dashboard/education-workshop',
              },
              {
                image: 'applied_jobs.png',
                total: allJobsSearch.length,
                to: '/dashboard/applied-jobs-list',
              },
              // { image: "images.png", total: 4 },
            ].map((data, index) => {
              return (
                <div
                  key={index}
                  className='col-md-6 col-lg-4 col-xl-3 mt-4 mt-md-0'
                >
                  <DashboardGeneralCard {...data} />
                </div>
              );
            })}
          </div>
          <div className='d-flex justify-content-center w-100'>
            <hr className='w-75 ' />
          </div>
        </DashboardGeneralContainer>
      )}
      <div className='d-flex justify-content-between align-items-center'>
        <div className='ms-3 mb-1 fs-4 fw-bold'>News</div>
        <Link
          to='/dashboard/all/news'
          className='me-5 small'
          style={{ color: 'var(--primary)', cursor: 'pointer' }}
        >
          See All
        </Link>
      </div>
      <DashboardNewsListContainer className='row d-flex m-0 justify-content-center justify-content-xl-start'>
        <div className='row'>
          {['upload', ...(userData.news?.slice(0, 7) ?? [])].map(
            (data, index) => {
              return (
                <div
                  key={index}
                  className='col-md-6 col-lg-4 col-xl-3 mt-4 mt-md-0'
                >
                  {data === 'upload' ? (
                    <Link to={'/dashboard/upload-news'}>
                      <Card className='d-flex flex-column align-items-center justify-content-center'>
                        <i className='fas fa-plus fs-1'></i>
                        <h3 className='fs-5 '>News</h3>
                        <UtilsButton className='bg-light text-dark'>
                          Upload
                        </UtilsButton>
                      </Card>
                    </Link>
                  ) : (
                    <DashboardNewsCard newsData={data} />
                  )}
                </div>
              );
            }
          )}
        </div>
      </DashboardNewsListContainer>
      <div className='d-flex justify-content-center w-100'>
        <hr className='w-75 ' />
      </div>

      <div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='ms-3 mb-1 fs-4 fw-bold'>Projects</div>
          <Link
            to='/dashboard/all/projects'
            className='me-5 small'
            style={{ color: 'var(--primary)', cursor: 'pointer' }}
          >
            See All
          </Link>
        </div>
        <DashboardProjectsListContainer className='row d-flex m-0 justify-content-center justify-content-xl-start'>
          <div className='row'>
            {['upload', ...(userData.projects?.slice(0, 7) ?? [])].map(
              (data, index) => {
                return (
                  <div
                    key={index}
                    className='col-md-6 col-lg-4 col-xl-3 mt-4 mt-md-0'
                  >
                    {data === 'upload' ? (
                      <Link to={'/dashboard/update-project'}>
                        <Card className='d-flex flex-column align-items-center justify-content-center'>
                          <i className='fas fa-plus fs-1'></i>
                          <h3 className='fs-5 '>Projects</h3>
                          <UtilsButton className='bg-light text-dark'>
                            Upload
                          </UtilsButton>
                        </Card>
                      </Link>
                    ) : (
                      <DashboardProjectCard projectData={data} />
                    )}
                  </div>
                );
              }
            )}
          </div>
        </DashboardProjectsListContainer>
        <div className='d-flex justify-content-center w-100'>
          <hr className='w-75 ' />
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='ms-3 mb-1 fs-4 fw-bold'>Publications</div>
          <Link
            to='/dashboard/all/publications'
            className='me-5 small'
            style={{ color: 'var(--primary)', cursor: 'pointer' }}
          >
            See All
          </Link>
        </div>
        <DashboardPublicationListContainer className='row d-flex m-0 justify-content-center justify-content-xl-start'>
          <div className='row'>
            {['upload', ...(userData.publications?.slice(0, 7) ?? [])].map(
              (data, index) => {
                return (
                  <div
                    key={index}
                    className='col-md-6 col-lg-4 col-xl-3 mt-4 mt-md-0'
                  >
                    {data === 'upload' ? (
                      <Link to={'/dashboard/update-publication'}>
                        <Card className='d-flex flex-column align-items-center justify-content-center'>
                          <i className='fas fa-plus fs-1'></i>
                          <h3 className='fs-5 '>Publications</h3>
                          <UtilsButton className='bg-light text-dark'>
                            Upload
                          </UtilsButton>
                        </Card>
                      </Link>
                    ) : (
                      <DashboardPublicationCard publicationData={data} />
                    )}
                  </div>
                );
              }
            )}
          </div>
        </DashboardPublicationListContainer>
        <div className='d-flex justify-content-center w-100'>
          <hr className='w-75 ' />
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='ms-3 mb-1 fs-4 fw-bold'>Education</div>
          <Link
            to='/dashboard/all/education'
            className='me-5 small'
            style={{ color: 'var(--primary)', cursor: 'pointer' }}
          >
            See All
          </Link>
        </div>
        <DashboardProjectsListContainer className='row d-flex m-0 justify-content-center justify-content-xl-start'>
          <div className='row'>
            {['upload', ...(userData.education?.slice(0, 7) ?? [])].map(
              (data, index) => {
                return (
                  <div
                    key={index}
                    className='col-md-6 col-lg-4 col-xl-3 mt-4 mt-md-0'
                  >
                    {data === 'upload' ? (
                      <Link to={'/dashboard/update-education'}>
                        <Card className='d-flex flex-column align-items-center justify-content-center'>
                          <i className='fas fa-plus fs-1'></i>
                          <h3 className='fs-5 '>Education</h3>
                          <UtilsButton className='bg-light text-dark'>
                            Upload
                          </UtilsButton>
                        </Card>
                      </Link>
                    ) : (
                      <DashboardEducationCard educationData={data} />
                    )}
                  </div>
                );
              }
            )}
          </div>
        </DashboardProjectsListContainer>

        <div className='d-flex justify-content-center w-100'>
          <hr className='w-75 ' />
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='ms-3 mb-1 fs-4 fw-bold'>Workshops</div>
          <Link
            to='/dashboard/all/workshops'
            className='me-5 small'
            style={{ color: 'var(--primary)', cursor: 'pointer' }}
          >
            See All
          </Link>
        </div>

        <DashboardWorkshopListContainer className='row d-flex m-0 justify-content-center justify-content-xl-start'>
          <div className='row'>
            {['upload', ...(userData.workshops?.slice(0, 7) ?? [])].map(
              (data, index) => {
                return (
                  <div
                    key={index}
                    className='col-md-6 col-lg-4 col-xl-3 mt-4 mt-md-0'
                  >
                    {data === 'upload' ? (
                      <Link to={'/dashboard/update-workshop'}>
                        <Card className='d-flex flex-column align-items-center justify-content-center'>
                          <i className='fas fa-plus fs-1'></i>
                          <h3 className='fs-5 '>Workshop</h3>
                          <UtilsButton className='bg-light text-dark'>
                            Upload
                          </UtilsButton>
                        </Card>
                      </Link>
                    ) : (
                      <DashboardWorkshopCard workshopData={data} />
                    )}
                  </div>
                );
              }
            )}
          </div>
        </DashboardWorkshopListContainer>

        <div className='d-flex justify-content-center w-100'>
          <hr className='w-75 ' />
        </div>

        {userData.role === 'admin' && (
          <div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='ms-3 mb-1 fs-4 fw-bold'>Jobs</div>
              <Link
                to='/dashboard/all/jobs'
                className='me-5 small'
                style={{ color: 'var(--primary)', cursor: 'pointer' }}
              >
                See All
              </Link>
            </div>
            <DashboardJobsListContainer className='row d-flex m-0 justify-content-center justify-content-xl-start'>
              <div className='row'>
                {['upload', ...(userData.jobs?.slice(0, 7) ?? [])].map(
                  (data, index) => {
                    return (
                      <div
                        key={index}
                        className='col-md-6 col-lg-4 col-xl-3 mt-4 mt-md-0'
                      >
                        {data === 'upload' ? (
                          <Link to={'/dashboard/update-job'}>
                            <Card className='d-flex flex-column align-items-center justify-content-center'>
                              <i className='fas fa-plus fs-1'></i>
                              <h3 className='fs-5 '>Jobs</h3>
                              <UtilsButton className='bg-light text-dark'>
                                Upload
                              </UtilsButton>
                            </Card>
                          </Link>
                        ) : (
                          <DashboardJobCard jobData={data} />
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            </DashboardJobsListContainer>
            <div className='d-flex justify-content-center w-100'>
              <hr className='w-75 ' />
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='ms-3 mb-1 fs-4 fw-bold'>Notes</div>
              <Link
                to='/dashboard/all/notes'
                className='me-5 small'
                style={{ color: 'var(--primary)', cursor: 'pointer' }}
              >
                See All
              </Link>
            </div>
            <DashboardNotesListContainer className='row d-flex m-0 justify-content-center justify-content-xl-start'>
              <div className='row'>
                {['upload', ...(userData.notes?.slice(0, 7) ?? [])].map(
                  (data, index) => {
                    return (
                      <div
                        key={index}
                        className='col-md-6 col-lg-4 col-xl-3 mt-4 mt-md-0'
                      >
                        {data === 'upload' ? (
                          <Link to={'/dashboard/update-notes'}>
                            <Card className='d-flex flex-column align-items-center justify-content-center'>
                              <i className='fas fa-plus fs-1'></i>
                              <h3 className='fs-5 '>Notes</h3>
                              <UtilsButton className='bg-light text-dark'>
                                Upload
                              </UtilsButton>
                            </Card>
                          </Link>
                        ) : (
                          <DashboardNotesCard notesData={data} />
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            </DashboardNotesListContainer>
          </div>
        )}
        <div className='d-flex justify-content-center w-100'>
          <hr className='w-75 ' />
        </div>
        <div className='d-flex justify-content-center w-100'>
          <hr className='w-75 ' />
        </div>
      </div>
    </DashboardMainContainer>
  );
};

export default DashboardNewsList;

const DashboardMainContainer = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
`;

const DashboardGeneralContainer = styled.div``;

const DashboardNewsListContainer = styled.div`
  margin-top: 4rem;
  @media (max-width: 577px) {
    margin-top: 0.5rem;
  }
`;

const DashboardProjectsListContainer = styled.div`
  margin-top: 4rem;
  @media (max-width: 577px) {
    margin-top: 0.5rem;
  }
`;
const DashboardPublicationListContainer = styled.div`
  margin-top: 4rem;
  @media (max-width: 577px) {
    margin-top: 0.5rem;
  }
`;
const DashboardNotesListContainer = styled.div`
  margin-top: 4rem;
  @media (max-width: 577px) {
    margin-top: 0.5rem;
  }
`;
const DashboardWorkshopListContainer = styled.div`
  margin-top: 4rem;
  @media (max-width: 577px) {
    margin-top: 0.5rem;
  }
`;

const DashboardJobsListContainer = styled.div`
  margin-top: 4rem;
  @media (max-width: 577px) {
    margin-top: 0.5rem;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  box-shadow: var(--shadow-1);
  border-radius: 1.375rem;
  height: 12rem;
`;
