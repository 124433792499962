import { Outlet } from 'react-router-dom';
import { UtilsButtonLink } from '../GlobalStyles';
import { useIsActiveLink } from '../hooks/useIsActiveLink';

const UserInfo: React.FC = () => {
  return (
    <div className='row'>
      <div className='col-12 col-xl-3'>
        <div className='d-flex flex-column align-items-center'>
          <UtilsButtonLink
            to='/dashboard'
            className={useIsActiveLink('/dashboard')}
          >
            Home
          </UtilsButtonLink>
          <UtilsButtonLink to='#' className='bg-light my-4 text-dark mx-md-0'>
            Personal Information
          </UtilsButtonLink>
          <UtilsButtonLink
            to='/dashboard/update-user/change-password'
            className={useIsActiveLink(
              '/dashboard/update-user/change-password'
            )}
          >
            Password
          </UtilsButtonLink>
        </div>
      </div>
      <div className='col-12 col-xl-9'>
        <Outlet />
      </div>
    </div>
  );
};

export default UserInfo;
