import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { useActions } from "../../hooks/useActions";
import { useSockets } from "../../context/socket.context";

export interface JobData {
  jobData: {
    id: string;
    title: string;
    description: string;
    jobType: string;
    period: string;
    jobImage: string;
    body: string;
    createdAt: Date;
    projectLink: string;
  };
}

const DashboardJobCard: React.FC<JobData> = ({ jobData }) => {
  const { deleteProject } = useActions();
  const { socket } = useSockets();

  return (
    <div className="mb-3">
      <Card>
        <article>
          <div className="row m-0">
            <div className="p-3">
              <h3
                className=""
                style={{ fontSize: ".875rem", color: "#bcc0c3" }}
              >
                {jobData && moment(jobData.createdAt).format("llll")}
              </h3>
              <h3 className="fs-5 ">
                {jobData && jobData.title.length < 25
                  ? jobData.title
                  : `${jobData.title.substr(0, 30)}...`}
              </h3>
              <p
                className=""
                style={{
                  // height: '10rem',
                  fontStyle: "italic",
                  color: "#525c65",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  lineHeight: "1.5rem",
                }}
              >
                {jobData && jobData.body.length < 25
                  ? jobData.body
                  : `${jobData.body.substr(0, 30)}...`}
              </p>
              <div className="d-flex justify-content-between">
                <Link to={`/dashboard/update-job/${jobData.id}`}>
                  <div className="p-2 bg-light rounded-3">
                    <i className="fas fa-pencil-alt"></i>
                  </div>
                </Link>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    socket.emit("deleteProject");
                    deleteProject(jobData.id);
                  }}
                  className="p-2 bg-light rounded-3"
                >
                  <i className="fas fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Card>
    </div>
  );
};

export default DashboardJobCard;

const Card = styled.div`
  background-color: #ffffff;
  box-shadow: var(--shadow-1);
  border-radius: 1.375rem;
  height: 12rem;
`;
