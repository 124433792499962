import { createContext, useContext } from 'react';
import io from 'socket.io-client';

export const SOCKET_URL =
  process.env.SOCKET_URL || 'https://city-umd.onrender.com/';

const socket = io(SOCKET_URL);

const SocketContext = createContext({ socket });

function SocketsProvider(props: any) {
  return <SocketContext.Provider value={{ socket }} {...props} />;
}

export const useSockets = () => useContext(SocketContext);

export default SocketsProvider;
