import { ActionType } from '../action-types';
import { Action } from '../actions';

interface EducationState {
    loading: boolean;
    error: string | null;
    data: any;
}

const initialState = {
    loading: false,
    error: null,
    data: []
}

const reducer = (state: EducationState = initialState, action: Action): EducationState => {
    switch (action.type) {
        case ActionType.POST_EDUCATION:
            return { loading: true, error: null, data: [] }
        case ActionType.POST_EDUCATION_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.POST_EDUCATION_ERROR:
            return { loading: false, error: action.payload, data: [] }
        case ActionType.SEARCH_EDUCATION:
            return { loading: true, error: null, data: [] }
        case ActionType.SEARCH_EDUCATION_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.SEARCH_EDUCATION_ERROR:
            return { loading: false, error: action.payload, data: [] }
        default:
            return state;
    };
};

export default reducer;
