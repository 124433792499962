import { ActionType } from '../action-types';
import { Action, NewsData } from '../actions';

interface NewsState {
    loading: Boolean;
    error: string | null;
    data: any;
    indvData?: NewsData;
};

const initialState = {
    loading: false,
    error: null,
    data: [],
    indvData: {
        title: '',
        body: '',
        createdAt: new Date(),
        imageCover: '',
        imageCredits: ''
    }
};



const reducer = (state: NewsState = initialState, action: Action): NewsState => {
    switch (action.type) {
        case ActionType.SEARCH_NEWS:
            return { loading: true, error: null, data: [] }
        case ActionType.SEARCH_NEWS_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.SEARCH_NEWS_ERROR:
            return { loading: false, error: action.payload, data: [] }
        case ActionType.SEARCH_INDV_NEWS_SUCCESS:
            return { loading: false, error: null, data: [], indvData: action.payload }
        case ActionType.POST_NEWS:
            return { loading: true, error: null, data: [] }
        case ActionType.POST_NEWS_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.POST_NEWS_ERROR:
            return { loading: false, error: action.payload, data: [] }
        case ActionType.DELETE_NEWS:
            return { loading: true, error: null, data: [] }
        case ActionType.DELETE_NEWS_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.DELETE_NEWS_ERROR:
            return { loading: false, error: action.payload, data: []}
        case ActionType.UPDATE_NEWS:
            return { loading: true, error: null, data: initialState.data };
        case ActionType.UPDATE_NEWS_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.UPDATE_NEWS_ERROR:
            return { loading: false, error: action.payload, data: [] } 
        default:
            return state;
    };
};

export default reducer;