import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { UserData } from '../../states/actions'

interface IndvUserData {
  email: string;
  name: string;
  _id: string;
  about: string;
  profileImage: string;
  skills: { title: string; highlight: boolean }[];
  expertise: { title: string; highlight: boolean }[];
  hobbies: { title: string; highlight: boolean }[];
  languages: { title: string; highlight: boolean }[];
  to?: string;
  linkedin?: string;
}

const AboutCard: React.FC<IndvUserData> = ({ ...data }) => {
  return (
    <div className='landing-page-card mb-2 mt-md-0'>
      {data && (
        <Link to={data.to ? `${data.to}/${data._id}` : `${data._id}`}>
          <Card style={{ borderTop: '6px solid var(--primary)' }}>
            <article>
              <div className='row m-0'>
                <AboutImage
                  className=' p-0'
                  src={data.profileImage}
                  alt='director'
                ></AboutImage>
                <h3
                  className='text-center '
                  style={{
                    fontWeight: 300,
                    fontSize: '1.75rem',
                    lineHeight: '2.25rem',
                    color: '#2e3d49',
                  }}
                >
                  {data.name}
                </h3>
                <p
                  className='text-center'
                  style={{
                    height: '10rem',
                    fontStyle: 'italic',
                    color: '#525c65',
                    fontWeight: 400,
                    padding: '0 1.25rem 1.25rem',
                    fontSize: '0.875rem',
                    lineHeight: '1.5rem',
                  }}
                >
                  {`${data && data.about && data.about.substring(0, 200)} ${
                    data && data.about && data.about.length < 200 ? '' : '...'
                  }`}
                </p>
              </div>
            </article>
          </Card>
        </Link>
      )}
      {data && data['linkedin'] && (
        <div style={{ position: 'relative', top: -40 }}>
          <AboutLines className='p-0 mb-1'>Contact</AboutLines>
          <div className='d-flex justify-content-center'>
            <a
              href={`https://linkedin.com/in/${data && data['linkedin']}`}
              target='_blank'
            >
              {' '}
              <i className='fab fa-linkedin-in mx-1'></i>
            </a>
          </div>
        </div>
      )}
    </div>
    // </div>
  );
};

export default AboutCard;

const Card = styled.div`
  background-color: #ffffff;
  box-shadow: var(--shadow);
  border-radius: 0.375rem;
  height: 21rem;
  /* transform: rotateY('0deg'); */
`;

const AboutImage = styled.img`
  border-radius: 50%;
  box-shadow: var(--shadow);
  height: 4rem;
  width: 4rem;
  margin: 0 auto;
  object-fit: cover;
  transform: translateY(-50%);
  max-width: 100%;
  object-position: center top;
`;
const AboutLines = styled.div`
  color: #bcc9d4;
  overflow: hidden;
  text-align: center;
  margin-top: -1rem;
  font-weight: 300;

  &::before {
    background-color: #dbe2e8;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 5rem;
    left: -0.5rem;
  }

  &::after {
    background-color: #dbe2e8;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 5rem;
    right: -0.5rem;
  }
`;
