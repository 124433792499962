import { combineReducers } from "redux";
import loginReducer from './loginReducer';
import newsReducer from './newsReducer';
import projectsReducer from './projectsReducer';
import publicationReducer from './publicationReducer';
import userReducer from './userReducer';
import allUsersReducer from './allUsersReducer';
import createUserReducer from "./createUserReducer";
import educationReducer from './educationReducer';
import workshopReducer from "./workshopReducer";
import applyJobReducer from "./applyJobReducer";
import contactUsReducer from "./contactUsReducer";
import jobsReducer from "./jobsReducer";
import notesReducer from "./notesReducer";
import searchReducer from "./searchReducer";

const reducers = combineReducers({
    newsReducer,
    loginReducer,
    userReducer,
    allUsersReducer,
    projectsReducer,
    publicationReducer,
    createUserReducer,
    educationReducer,
    workshopReducer,
    applyJobReducer,
    contactUsReducer,
    jobsReducer,
    notesReducer,
    searchReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
