import { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { useActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";

interface SearchBarProps {
  onHandleSearch: (data: boolean) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onHandleSearch }) => {
  const [searchOpen, setSearchOpen] = useState<boolean>(true);
  const [search, setSearch] = useState<boolean>(false);
  const [inputVal, setInputVal] = useState<string>('');
  
  const { searchWebpage } = useActions();
  const { data, loading } = useTypedSelector(
    (state) => state.searchReducer
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (data.length !== 0) { 
      navigate('/search', { state: data})
      // setSearchOpen(!searchOpen);
      onHandleSearch(searchOpen);
    } else {
      if (search) {
        navigate('/search', { state: data})
      //   // navigate('/search', { state: data})
        // onHandleSearch(searchOpen);

      }
      // setSearchOpen(!searchOpen);
    }
    return () => setSearchOpen(!searchOpen);
  }, [loading, data, searchOpen])

  const onHandleKeyPress = (e: any) => {
    if(e.key === "Enter") {
      setSearch(true);
      // console.log('he', e.target.value)
      searchWebpage(e.target.value);
     
      // if(data.length === 0 && search) {
      //   navigate('/search', { state: [] })

      //   onHandleSearch(!searchOpen);
      // }
    }
  }

  return (
    <SearchBarContainer initial={{ y: -250 }} animate={{ y: 0 }} transition={{ duration: .1 }} className="search-container d-flex d-flex row m-0 p-0 justify-content-center align-items-center">
        <div className="d-flex col-12 col-md-8 flex-column">
            <i
            onClick={() => onHandleSearch(!searchOpen)}
            style={{ color: "#525c65", cursor: "pointer", width: "100%" }}
            className="fa fa-times fs-4 text-end"
            aria-hidden="true"
            ></i>
            <SearchInput onKeyPress={(e: any) => onHandleKeyPress(e)} autoFocus initial={{ opacity: 0}} animate={{ opacity: 1 }} transition={{ delay: .5 }} 
            placeholder="Search for people, news, workshops, etc."
            // value={ data.length === 0 && search ? 'No results found' : inputVal}
            // onChange={(val: any) => setInputVal(val)}
            type="text"
            />
        </div>
    </SearchBarContainer>
  );
};

export default SearchBar;


const SearchBarContainer = styled(motion.div)`
  background-color: #000;
  height: 10rem;
  position: absolute;
  width: 100vw;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  overflow: hidden;
`;

const SearchInput = styled(motion.input)`
  outline: 0;
  border-width: 0 0 2px;
  border-color: #fff;
  background: transparent;
  padding-bottom: 0.5rem;
  width: 100%;
  font-size: 2rem;
  color: #fff;
  
  ::placeholder {
    color: gray;
    opacity: 1;
  }
`;
