import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const LoginButton = styled.button`
  font-size: 0.8125rem;
  line-height: 1.8125rem;
  height: 2rem;
  width: auto;
  background: ${(props) => (props.disabled ? '#EBEBE4' : 'var(--primary)')};
  color: ${(props) => (props.disabled ? '#000' : '#fff')};
  border: 0.125rem solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  letter-spacing: 0.125rem;
  transition: all 0.3s ease;
  text-transform: uppercase;
  border-radius: 0.125rem;
  box-shadow: 8px 10px 20px 0 rgb(46 61 73 / 15%);
`;

export const UtilsButton = styled.button`
  font-size: 0.825rem;
  line-height: 1.8125rem;
  height: 3rem;
  width: auto;
  background-color: var(--primary);
  color: #fff;
  border: 0.125rem solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.75rem;
  letter-spacing: 0.125rem;
  transition: all 0.3s ease;
  text-transform: uppercase;
  border-radius: 0.125rem;
  box-shadow: 8px 10px 20px 0 rgb(46 61 73 / 15%);
`;

export const UtilsButtonLink = styled(NavLink)`
  font-size: 1.125rem;
  line-height: 1.8125rem;
  height: 3rem;
  width: 100%;
  background-color: var(--primary);
  color: #000;
  border: 0.125rem solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.75rem;
  letter-spacing: 0.125rem;
  transition: all 0.3s ease;
  text-transform: uppercase;
  border-radius: 0.125rem;
  box-shadow: 8px 10px 20px 0 rgb(46 61 73 / 15%);
`;
