import { Link } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';

interface DashboardGeneralCardProps {
  image: string;
  total?: number;
  to?: string;
}

const DashboardGeneralCard: React.FC<DashboardGeneralCardProps> = ({
  image,
  total,
  to,
}) => {
  const [imageUrl, setImageUrl] = React.useState(image);

  React.useEffect(() => {
    // Update the image URL on route change
    setImageUrl(`${image}?timestamp=${new Date().getTime()}`);
  }, [image]);

  return (
    <div className='mb-3 mt-1'>
      <Link to={to || '/'}>
        <Card style={{ backgroundImage: `url(${imageUrl})` }}>
          {total && (
            <SmallCard className='fs-3 d-flex justify-content-center align-items-center fw-bold'>
              {total}
            </SmallCard>
          )}
        </Card>
      </Link>
    </div>
  );
};
export default DashboardGeneralCard;

const SmallCard = styled.div`
  background-color: var(--secondary);
  box-shadow: var(--shadow-1);
  height: 3rem;
  width: 3rem;
  position: absolute;
  right: 0;
  /* color: var(--primary) */
`;

const Card = styled.div`
  background-color: #ffffff;
  box-shadow: var(--shadow-1);
  border-radius: 1.375rem;
  height: 10rem;
  position: relative;
  background-image: ${(props) =>
    props.theme.image ? `url(${props.theme.image})` : ''};
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
