import React from 'react';
// third party components
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { LoginButton } from '../GlobalStyles';

interface NavbarProps {
  headerData: {
    name: string;
  }[];
  userData: {
    role: string;
    email: string;
    name: string;
    _id: string;
    news?: any[];
  };
  onHandleSearch: (data: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ headerData, onHandleSearch }) => {
  const [searchOpen, _] = React.useState<boolean>(false);

  return (
    <NavBar className='nav-bar my-3'>
      <div className='row d-flex align-items-center justify-content-center'>
        <Logo className='col-lg-2 d-flex p-0'>
          <HeaderLinks
            to='/'
            end
            className='d-flex p-0'
            style={{ color: '#000' }}
          >
            <img
              style={{ height: '3rem', width: '3rem' }}
              src='https://i.ibb.co/wS9Zn8m/city-logo.jpg'
              alt='logo of city@umd'
            />
            <LogoText className='mt-2 d-none d-lg-block'>City@UMD</LogoText>
          </HeaderLinks>
        </Logo>
        <div className='d-none d-lg-flex offset-1 col-lg-9 row'>
          {headerData.map((data, index) => (
            <div
              key={index}
              className='col-md-1 mx-auto d-flex align-items-center'
            >
              <HeaderLinks
                to={`/${data.name.toLowerCase()}`}
                end
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                {data.name}
              </HeaderLinks>
            </div>
          ))}
          <i
            onClick={() => onHandleSearch(!searchOpen)}
            className='fa col-md-1 fa-search my-2'
            aria-hidden='true'
            style={{ color: '#525c65', cursor: 'pointer' }}
          ></i>
          <div className='col-md-2'>
            <LoginButton>
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'on text-white' : 'text-white'
                }
                to={`/`}
              >
                Home
              </NavLink>
            </LoginButton>
          </div>
        </div>
      </div>
    </NavBar>
  );
};

export default Navbar;

const NavBar = styled.div``;

const LogoText = styled.p`
  /* text-transform: uppercase; */
  margin-left: 1rem;
  font-size: 1.75rem;
  font-family: 'Nanum Brush Script', cursive;
  font-weight: 700;
`;

const HeaderLinks = styled(NavLink)`
  letter-spacing: 0;
  font-weight: 400;
  color: #525c65;
  background-color: transparent;
  border: 0.125rem soid transparent;
  border-radius: 0;
  transition: opacity 0.3s ease;
  padding: 0 0.5rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #ca4557;
  }

  &img,
  p {
    position: relative;
    top: 0.2rem;
  }
`;

const Logo = styled.header`
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 577px) {
    margin-bottom: -2rem;
  }
`;
