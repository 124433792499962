import { ActionType } from '../action-types';
import { Action } from '../actions';

interface SearchState {
    loading: boolean;
    error: string | null;
    data: any[];
}

const initialState = {
    loading: false,
    error: null,
    data: []
}

const reducer = (state: SearchState = initialState, action: Action): SearchState => {
    switch (action.type) {
        case ActionType.SEARCH_SEARCH:
            return { loading: true, error: null, data: [] }
        case ActionType.SEARCH_SEARCH_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.SEARCH_SEARCH_ERROR:
            return { loading: false, error: action.payload, data: [] }
        default:
            return state;
    };
};

export default reducer;
