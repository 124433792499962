import styled from "styled-components";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useActions } from "../hooks/useActions";
import { useNavigate } from "react-router-dom";
import { useSockets } from "../context/socket.context";

interface ModalProps {
  modal: boolean;
  onSelectedChange: (data: boolean) => void;
}

const Modal: React.FC<ModalProps> = ({ modal, onSelectedChange }) => {
  const { createUser } =
    useActions();
  const { data,  loading, error } = useTypedSelector((state) => state.createUserReducer);
  const [loader, setLoader] = useState(false);
  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  const { socket } = useSockets();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    if (error !== null && err) {
      toast.error(
        "Seems like there was an error. Please check your fields and make sure they are unique!"
      );
      setLoader(false)
    } else if (
      data.email && 
      err &&
      error === null && loader
    ) {
      toast.success(`User ${data.name} has been registered,redirecting to dashboard..`);
      setTimeout(() => {
        navigate("/dashboard/all-users");
        socket.emit("refreshDashboard");
      }, 2000);
    } 
  }, [err, error, loading]);

  const generatePassword = () => {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}


  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onSelectedChange(!modal);
      }
    };
    window.addEventListener("keydown", close);
    return () => {
      window.removeEventListener("keydown", close);
    };
  }, [modal, onSelectedChange]);
  const required = (value: string) => (value ? undefined : "Required");

  return ReactDOM.createPortal(
    <ModalContainer onClick={() => onSelectedChange(!modal)}>
      <ModalContent
        onClick={(
          e: React.BaseSyntheticEvent<
            MouseEvent,
            EventTarget & HTMLDivElement,
            EventTarget
          >
        ) => e.stopPropagation()}
      >
        <div className="d-flex justify-content-between">
          <p style={{ fontWeight: 300, fontSize: "1.75rem" }}>Add User</p>
          <i
            onClick={() => onSelectedChange(!modal)}
            style={{ color: "var(--primary)", cursor: "pointer" }}
            className="fas fa-times"
          ></i>
        </div>
        <Form
          onSubmit={({ name, email }) => {
            const password = generatePassword()
            createUser(name, email, password, password);
          }}
        >
          {({ handleSubmit, pristine }) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();        
                  setLoader(true) 
                }}
              >
                <div>
                  <Field name="name" validate={required}>
                    {({ meta, input }) => {
                      return (
                        <div>
                          <InputBox
                            placeholder="Name"
                            id="name"
                            type="text"
                            {...input}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </div>
                      );
                    }}
                  </Field>
                  <Field name="email" validate={required}>
                    {({ meta, input }) => {
                      return (
                        <div>
                          <InputBox
                            placeholder="Email"
                            id="email"
                            type="email"
                            {...input}
                          />
                          {meta.error && meta.touched && (
                            <span className="text-danger">{meta.error}</span>
                          )}
                        </div>
                      );
                    }}
                  </Field>
                  <div className="text-end">
                    <SignupButton
                        type="submit"
                        disabled={pristine}
                        onClick={() => {
                          setErr(true);
                        }}
                        
                    >
                        {loader && !error ? 'Submitting...' : 'Submit'}
                    </SignupButton>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
        <ToastContainer />
      </ModalContent>
    </ModalContainer>,
    document.getElementById("modal") as HTMLElement
  );
};

export default Modal;

export const InputBox = styled.input`
  color: #2e3d49;
  padding: 10px 5px;
  border: 1px solid #dbe2e8;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 0.875rem;
  height: 3rem;
  outline: none;

  &:active,
  :focus {
    border: 1px solid var(--primary);
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(33, 37, 41, 0.8);
  top: 0;
  display: flex;
`;

const ModalContent = styled.div`
  width: 80vw;
  padding: 1rem;
  background-color: #fff;
  margin: auto;
  border-radius: 0.375rem;

  @media (min-width: 1201px) {
    width: 30vw;
    padding: 3rem;
  }

  @media (max-width: 1025px) {
    width: 50vw;
    padding: 3rem;
  }

  @media (max-width: 768px) {
    width: 80vw;
  }
`;

export const SignupButton = styled.button`
  height: 3rem;
  padding: 0 1rem;
  background: var(--primary);
  border: transparent;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  letter-spacing: 1.5px;
  line-height: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
`;
