import { ActionType } from '../action-types';
import { Action } from '../actions';

interface CreateUserState {
    loading: boolean;
    error: string | null;
    data: {
        name: string;
        email: string;
        password: string;
        passwordConfirm: string;
    }
}

const initialState = {
    loading: false,
    error: null,
    data: { 
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
    }
}

const reducer = (state: CreateUserState = initialState, action: Action): CreateUserState => {
    switch (action.type) {
        case ActionType.CREATE_USER:
            return { ...initialState, loading: true}
        case ActionType.CREATE_USER_SUCCESS:
            return {
                loading: false, 
                error: null,
                data: action.payload
            }
        case ActionType.CREATE_USER_ERROR:
            return { ...initialState, error: action.payload}
            case ActionType.DELETE_USER:
                return { loading: true, error: null, data: { 
                    name: '',
                    email: '',
                    password: '',
                    passwordConfirm: '',
                } }
            case ActionType.DELETE_USER_SUCCESS:
                return { loading: false, error: null, data: action.payload }
            // case ActionType.DELETE_USER_ERROR:
            //     return { loading: false, error: action.payload, data: []}
        default:
            return state
    }
}

export default reducer;
