import axios from 'axios';
import { Dispatch } from 'react';
import { ActionType } from '../action-types';
import { Action } from '../actions';

export const searchNews = (pageNo = 1, limit = 7, filter = 'All') => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEARCH_NEWS,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/news?page=${pageNo}&limit=${limit}${
          filter === 'All' ? null : `&newsCategory=${filter}`
        }`
      );
      dispatch({
        type: ActionType.SEARCH_NEWS_SUCCESS,
        payload: data.data,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_NEWS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const searchPublications = (pageNo = 1, limit = 8, filter = 'All') => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.SEARCH_PUBLICATIONS });

    let queryString = `https://city-umd.onrender.com/api/v1/publications?page=${pageNo}&limit=${limit}`;
    if (filter !== 'All') {
      queryString += `&publicationTag=${filter}`;
    }

    try {
      const { data } = await axios.get(queryString);
      dispatch({
        type: ActionType.SEARCH_PUBLICATIONS_SUCCESS,
        payload: data.data,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_PUBLICATIONS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const searchIndvNews = (id: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEARCH_NEWS,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/news/${id}`
      );
      dispatch({
        type: ActionType.SEARCH_INDV_NEWS_SUCCESS,
        payload: data.data.news,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_NEWS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const loginData = (email: string, password: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.LOGIN,
    });
    try {
      const { data } = await axios.post(
        `https://city-umd.onrender.com/api/v1/users/login`,
        {
          email,
          password,
        },
        { withCredentials: true }
      );

      if (data.status === 'success') {
        window.setTimeout(() => {
          window.location.assign('/dashboard');
        }, 1000);
      }
    } catch (err: any) {
      dispatch({
        type: ActionType.LOGIN_ERROR,
        payload: err.message,
      });
    }
  };
};

export const currentUserData = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CURRENT_USER,
    });
    try {
      const { data } = await axios.get(
        'https://city-umd.onrender.com/api/v1/users/currentUser',
        {
          withCredentials: true,
          headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      dispatch({
        type: ActionType.CURRENT_USER_SUCCESS,
        payload: data.data.user,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.CURRENT_USER_ERROR,
        payload: err.message,
      });
    }
  };
};

export const logout = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.LOGOUT,
    });
    try {
      const { data } = await axios.get(
        'https://city-umd.onrender.com/api/v1/users/logout',
        { withCredentials: true }
      );
      dispatch({
        type: ActionType.LOGOUT_SUCCESS,
        payload: {
          role: 'student',
          email: '',
          name: '',
          _id: '',
        },
      });

      if (data.status === 'success') {
        window.setTimeout(() => {
          window.location.assign('/');
        }, 1000);
      }
    } catch (err: any) {
      dispatch({
        type: ActionType.LOGOUT_ERROR,
        payload: err.message,
      });
    }
  };
};

export const createUser = (
  name: string,
  email: string,
  password: string,
  passwordConfirm: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CREATE_USER,
    });

    try {
      const { data } = await axios.post(
        `https://city-umd.onrender.com/api/v1/users/signup`,
        {
          name,
          email,
          password,
          passwordConfirm,
        },
        { withCredentials: true }
      );

      dispatch({
        type: ActionType.CREATE_USER_SUCCESS,
        payload: data.data.user,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.CREATE_USER_ERROR,
        payload: err.message,
      });
    }
  };
};

export const deleteUser = (userId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.DELETE_USER,
    });
    try {
      console.log('userID', userId);
      await axios.delete(
        `https://city-umd.onrender.com/api/v1/users/${userId}`,
        {
          withCredentials: true,
        }
      );
      // dispatch({
      //     type: ActionType.DELETE_NEWS_SUCCESS,
      //     payload: []
      // })
    } catch (err: any) {
      dispatch({
        type: ActionType.DELETE_USER_ERROR,
        payload: err.message,
      });
    }
  };
};

export const updatePasswordDetails = (
  passwordCurrent: string,
  password: string,
  passwordConfirm: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_USER,
    });
    try {
      const { data } = await axios.patch(
        `https://city-umd.onrender.com/api/v1/users/updateMyPassword`,
        {
          passwordCurrent,
          password,
          passwordConfirm,
        },
        { withCredentials: true }
      );

      dispatch({
        type: ActionType.UPDATE_USER_SUCCESS,
        payload: data.data.user,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.LOGIN_ERROR,
        payload: err.message,
      });
    }
  };
};

export const updateUserDetails = (
  displayName: string,
  about: string,
  skills: any,
  expertise: any,
  hobbies: any,
  languages: any,
  lifePhil: string,
  linkedin: string,
  twitter: string,
  link: string,
  instagram: string,
  facebook: string,
  profileImage: string,
  attachments: [any]
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_USER,
    });
    try {
      const { data } = await axios.patch(
        `https://city-umd.onrender.com/api/v1/users/updateMe`,
        {
          name: displayName,
          about,
          skills,
          expertise,
          hobbies,
          languages,
          lifePhil,
          linkedin,
          twitter,
          link,
          instagram,
          facebook,
          profileImage,
          attachments,
        },
        { withCredentials: true }
      );

      dispatch({
        type: ActionType.UPDATE_USER_SUCCESS,
        payload: data.data.user,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.LOGIN_ERROR,
        payload: err.message,
      });
    }
  };
};

export const getAllUsers = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ALL_USERS,
    });
    try {
      const { data } = await axios.get(
        'https://city-umd.onrender.com/api/v1/users',
        {
          withCredentials: true,
        }
      );
      dispatch({
        type: ActionType.ALL_USERS_SUCCESS,
        payload: data.data.users,
      });

      console.log('data', data.data.users);
    } catch (err: any) {
      dispatch({
        type: ActionType.ALL_USERS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const getUser = (id: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ALL_USERS,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/users/${id}`
      );
      dispatch({
        type: ActionType.ALL_USERS_INDV_SUCCESS,
        payload: data.data.data,
      });
      // console.log('DAT', data.data.data)
    } catch (err: any) {
      dispatch({
        type: ActionType.ALL_USERS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const postNews = (
  title: string,
  body: string,
  imageCredits: string,
  _id: string,
  imageCover: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.POST_NEWS,
    });
    try {
      const { data } = await axios.post(
        `https://city-umd.onrender.com/api/v1/news`,
        {
          title,
          body,
          imageCredits,
          imageCover,
          user: _id,
        },
        { withCredentials: true }
      );
      dispatch({
        type: ActionType.POST_NEWS_SUCCESS,
        payload: data.data.news,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.POST_NEWS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const deleteNews = (newsId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.DELETE_NEWS,
    });
    try {
      console.log('newsID', newsId);
      await axios.delete(
        `https://city-umd.onrender.com/api/v1/news/${newsId}`,
        {
          withCredentials: true,
        }
      );
      // dispatch({
      //     type: ActionType.DELETE_NEWS_SUCCESS,
      //     payload: []
      // })
    } catch (err: any) {
      dispatch({
        type: ActionType.DELETE_NEWS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const updateNewsDetails = (
  newsId: string,
  title: string,
  body: string,
  imageCredits: string,
  imageCover: string,
  _id: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_NEWS,
    });
    try {
      const { data } = await axios.patch(
        `https://city-umd.onrender.com/api/v1/news/${newsId}`,
        {
          title,
          body,
          imageCredits,
          imageCover,
          user: _id,
        },
        { withCredentials: true }
      );

      dispatch({
        type: ActionType.UPDATE_NEWS_SUCCESS,
        payload: data.data,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.LOGIN_ERROR,
        payload: err.message,
      });
    }
  };
};

export const updateProjectDetails = (
  projectId: string,
  title: string,
  body: string,
  projectImage: string,
  projectLink: string,
  _id: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_PROJECT,
    });
    try {
      const { data } = await axios.patch(
        `https://city-umd.onrender.com/api/v1/projects/${projectId}`,
        {
          title,
          body,
          projectImage,
          projectLink,
          user: _id,
        },
        { withCredentials: true }
      );

      console.log(data);
      dispatch({
        type: ActionType.UPDATE_PROJECT_SUCCESS,
        payload: data.data,
      });
    } catch (err: any) {
      console.log(err);
      dispatch({
        type: ActionType.UPDATE_PROJECT_ERROR,
        payload: err.message,
      });
    }
  };
};

export const postProject = (
  title: string,
  body: string,
  projectImage: string,
  projectLink: string,
  _id: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.POST_PROJECT,
    });
    try {
      const { data } = await axios.post(
        `https://city-umd.onrender.com/api/v1/projects`,
        {
          title,
          body,
          projectImage,
          projectLink,
          user: _id,
        },
        { withCredentials: true }
      );
      dispatch({
        type: ActionType.POST_PROJECT_SUCCESS,
        payload: data.data.project,
      });

      // console.log('pay', data.data.project)
    } catch (err: any) {
      console.log(err, 'err');
      dispatch({
        type: ActionType.POST_PROJECT_ERROR,
        payload: err.message,
      });
    }
  };
};

export const searchProjects = (pageNo = 1) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEARCH_PROJECTS,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/projects?page=${pageNo}`
      );
      dispatch({
        type: ActionType.SEARCH_PROJECTS_SUCCESS,
        payload: data.data.projects,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_PROJECTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const deleteProject = (projectId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.DELETE_PROJECT,
    });
    try {
      console.log('newsID', projectId);
      await axios.delete(
        `https://city-umd.onrender.com/api/v1/projects/${projectId}`,
        {
          withCredentials: true,
        }
      );
      // dispatch({
      //     type: ActionType.DELETE_NEWS_SUCCESS,
      //     payload: []
      // })
    } catch (err: any) {
      dispatch({
        type: ActionType.DELETE_PROJECT_ERROR,
        payload: err.message,
      });
    }
  };
};

export const searchIndvProject = (id: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEARCH_PROJECTS,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/projects/${id}`
      );
      dispatch({
        type: ActionType.SEARCH_INDV_PROJECT_SUCCESS,
        payload: data.data.project,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_PROJECTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const postPublication = (
  title: string,
  authors: string,
  publicationTag: string,
  publicationLink: string,
  _id: string,
  publicationImage: string,
  citations: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.POST_PUBLICATION,
    });
    try {
      const { data } = await axios.post(
        `https://city-umd.onrender.com/api/v1/publications`,
        {
          title,
          authors,
          publicationTag,
          publicationLink,
          user: _id,
          publicationImage,
          citations,
        },
        { withCredentials: true }
      );
      dispatch({
        type: ActionType.POST_PUBLICATION_SUCCESS,
        payload: data.data,
      });
    } catch (err: any) {
      console.log('err', err.message);
      dispatch({
        type: ActionType.POST_PUBLICATION_ERROR,
        payload: err.message,
      });
    }
  };
};

export const deletePublication = (publicationId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.DELETE_PUBLICATION,
    });
    try {
      await axios.delete(
        `https://city-umd.onrender.com/api/v1/publications/${publicationId}`,
        { withCredentials: true }
      );
      // dispatch({
      //     type: ActionType.DELETE_NEWS_SUCCESS,
      //     payload: []
      // })
    } catch (err: any) {
      dispatch({
        type: ActionType.DELETE_PUBLICATION_ERROR,
        payload: err.message,
      });
    }
  };
};

export const searchIndvPublication = (id: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEARCH_PUBLICATIONS,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/publications/${id}`
      );
      dispatch({
        type: ActionType.SEARCH_INDV_PUBLICATION_SUCCESS,
        payload: data.data.publication,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_PUBLICATIONS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const updatePublicationDetails = (
  publicationId: string,
  title: string,
  authors: string,
  publicationTag: string,
  publicationLink: string,
  publicationImage: string,
  citations: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_PUBLICATION,
    });
    try {
      console.log('puvl', publicationImage);
      const { data } = await axios.patch(
        `https://city-umd.onrender.com/api/v1/publications/${publicationId}`,
        {
          title,
          authors,
          publicationTag,
          publicationLink,
          citations,
          publicationImage,
        },
        { withCredentials: true }
      );

      dispatch({
        type: ActionType.UPDATE_PUBLICATION_SUCCESS,
        payload: data.data,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.UPDATE_PUBLICATION_ERROR,
        payload: err.message,
      });
    }
  };
};

export const postEducation = (
  title: string,
  body: string,
  branch: string,
  _id: string,
  instructorImage: string,
  instructorName: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.POST_EDUCATION,
    });
    try {
      const { data } = await axios.post(
        `https://city-umd.onrender.com/api/v1/education`,
        {
          title,
          body,
          branch,
          instructorImage,
          instructorName,
          user: _id,
        },
        { withCredentials: true }
      );
      dispatch({
        type: ActionType.POST_EDUCATION_SUCCESS,
        payload: data.data.education,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.POST_EDUCATION_ERROR,
        payload: err.message,
      });
    }
  };
};

export const searchEducation = (pageNo = 1, limit = 8) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEARCH_EDUCATION,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/education?page=${pageNo}&limit=${limit}`
      );
      dispatch({
        type: ActionType.SEARCH_EDUCATION_SUCCESS,
        payload: data.data,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_EDUCATION_ERROR,
        payload: err.message,
      });
    }
  };
};

export const postWorkshop = (
  title: string,
  workshopLink: string,
  _id: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.POST_WORKSHOP,
    });
    try {
      const { data } = await axios.post(
        `https://city-umd.onrender.com/api/v1/workshops`,
        {
          title,
          workshopLink,
          user: _id,
        },
        { withCredentials: true }
      );
      dispatch({
        type: ActionType.POST_WORKSHOP_SUCCESS,
        payload: data.data.workshop,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.POST_WORKSHOP_ERROR,
        payload: err.message,
      });
    }
  };
};

export const searchWorkshops = (pageNo = 1) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEARCH_WORKSHOP,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/workshops`
      );
      dispatch({
        type: ActionType.SEARCH_WORKSHOP_SUCCESS,
        payload: data.data.workshops,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_WORKSHOP_ERROR,
        payload: err.message,
      });
    }
  };
};

export const postApplyJob = (
  name: string,
  poi: string,
  body: string,
  resume: [any],
  email: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.POST_APPLY_JOB,
    });
    try {
      const { data } = await axios.post(
        `https://city-umd.onrender.com/api/v1/applyJobs`,
        {
          name,
          body,
          poi,
          resume,
          email,
        },
        { withCredentials: true }
      );
      dispatch({
        type: ActionType.POST_APPLY_JOB_SUCCESS,
        payload: data.data.aplyJobs,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.POST_APPLY_JOB_ERROR,
        payload: err.message,
      });
    }
  };
};
export const searchApplyJob = (pageNo = 1) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEARCH_APPLY_JOB,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/applyJobs`
      );
      dispatch({
        type: ActionType.SEARCH_APPLY_JOB_SUCCESS,
        payload: data.data.applyJobs,
      });

      // console.log('data', data.data.contactUs)
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_APPLY_JOB_ERROR,
        payload: err.message,
      });
    }
  };
};

export const postContactUs = (name: string, body: string, email: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.POST_CONTACTUS,
    });
    try {
      const { data } = await axios.post(
        `https://city-umd.onrender.com/api/v1/contactUs`,
        {
          name,
          body,
          email,
        },
        { withCredentials: true }
      );
      dispatch({
        type: ActionType.POST_CONTACTUS_SUCCESS,
        payload: data.data.contactUs,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.POST_CONTACTUS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const searchContactUs = (pageNo = 1) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEARCH_CONTACTUS,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/contactUs`
      );
      dispatch({
        type: ActionType.SEARCH_CONTACTUS_SUCCESS,
        payload: data.data.contactUs,
      });

      // console.log('data', data.data.contactUs)
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_CONTACTUS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const postJob = (
  title: string,
  description: string,
  jobType: string,
  body: string,
  positionPlace: string,
  jobApplyLink: string,
  jobDescriptionLink: string,
  jobImage: string,
  _id: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.POST_JOB,
    });
    console.log(description, 'description');
    try {
      const { data } = await axios.post(
        `https://city-umd.onrender.com/api/v1/jobs`,
        {
          title,
          description,
          jobType,
          body,
          positionPlace,
          jobApplyLink,
          jobDescriptionLink,
          jobImage,
          user: _id,
        },
        { withCredentials: true }
      );
      dispatch({
        type: ActionType.POST_JOB_SUCCESS,
        payload: data.data.jobs,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.POST_JOB_ERROR,
        payload: err.message,
      });
    }
  };
};

export const searchJobs = (pageNo = 1) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEARCH_JOBS,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/jobs`
      );
      dispatch({
        type: ActionType.SEARCH_JOBS_SUCCESS,
        payload: data.data.jobs,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_JOBS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const postNotes = (title: string, _id: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.POST_NOTES,
    });
    try {
      const { data } = await axios.post(
        `https://city-umd.onrender.com/api/v1/notes`,
        {
          title,
          user: _id,
        },
        { withCredentials: true }
      );
      dispatch({
        type: ActionType.POST_NOTES_SUCCESS,
        payload: data.data.notes,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.POST_NOTES_ERROR,
        payload: err.message,
      });
    }
  };
};

export const searchWebpage = (search: string, pageNo = 1, limit = 8) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SEARCH_SEARCH,
    });
    try {
      const { data } = await axios.get(
        `https://city-umd.onrender.com/api/v1/search?page=${pageNo}&limit=${limit}&search=${search}`
      );
      dispatch({
        type: ActionType.SEARCH_SEARCH_SUCCESS,
        payload: data.data.data,
      });
      // console.log(data.data.data, 'FROM ACTION-CREATORS!')
    } catch (err: any) {
      dispatch({
        type: ActionType.SEARCH_SEARCH_ERROR,
        payload: err.message,
      });
    }
  };
};
