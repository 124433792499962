import styled from "styled-components";
import { useState, useEffect } from 'react';
import AboutCard from "../components/AboutCards/AboutCard";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useActions } from "../hooks/useActions";
import { UtilsButton } from "../GlobalStyles";
import Modal from "../components/Modal";
import { useNavigate } from "react-router-dom";



const AllUsers: React.FC = () => {
    const { getAllUsers, deleteUser } = useActions();
    const { data,  loading } = useTypedSelector((state) => state.allUsersReducer);
    const [allUsers, setAllUsers] = useState<any[]>([]);
    const [modal, setModal] = useState<boolean>(false);
    const navigate = useNavigate();
    useEffect(() => {
        getAllUsers();
    }, [])

    useEffect(() => {
        if (!loading && data.length !== 0) {
            setAllUsers(data);
        }
    }, [data])

    return <div>
       {modal ? <Modal modal={modal} onSelectedChange={(data) => setModal(data)} /> : ''}
       <UtilsButton
       onClick={() => setModal(!modal)}
       className="my-4 ms-auto"  style={{ height: '1.75rem', fontSize: '0.8rem'}}>Add user</UtilsButton>
       <AboutCardList>
        <div className="row d-flex m-0 justify-content-center justify-content-md-start">
            {allUsers.map((val, index) => {
                return <div className="col-md-6 col-lg-4 col-xl-3 col-10 mt-4 mb-5 mt-md-0" key={index}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    deleteUser(val._id);
                  }}
                  className="p-2  rounded-3"
                >
                  <i className="fas fa-trash"></i>
                </div>
                    <AboutCard to="/team" {...val} />
                </div>
            })}
            </div>
        </AboutCardList>
    </div>
};

export default AllUsers;

const AboutCardList = styled.div`
    margin-top: 2rem;
    @media(max-width: 577px) {
        margin-top: 0.5rem;
    }
`
