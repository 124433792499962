import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { LoginButton } from '../GlobalStyles';

interface HeaderData {
  headerData: {
    name: string;
  }[];
}

interface SidebarProps {
  onSelectedChange: (data: boolean) => void;
  onSelectedChangeLogo: (data: boolean) => void;
}

type props = HeaderData & SidebarProps;

const Sidebar: React.FC<props> = ({
  headerData,
  onSelectedChange,
  onSelectedChangeLogo,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return ReactDOM.createPortal(
    <div>
      <SideBar>
        <LogoText
          onClick={() => {
            onSelectedChangeLogo(false);
            onSelectedChange(false);
          }}
          className='text-white p-2 m-0 d-flex align-items-center justify-content-center'
        >
          <NavLink
            style={{ textDecoration: 'none' }}
            to='/'
            end
            className={({ isActive }) =>
              isActive ? 'on text-white d-flex' : 'text-white d-flex'
            }
          >
            <img
              style={{
                height: '2rem',
                width: '2rem',
                position: 'relative',
                right: '1rem',
              }}
              src='https://i.ibb.co/wS9Zn8m/city-logo.jpg'
              alt='logo of city@umd'
            />
            City@UMD
          </NavLink>
        </LogoText>
        {headerData.map((data, index) => {
          return (
            <div key={index} className='d-flex align-items-center'>
              <HeaderLinks
                onClick={() => {
                  onSelectedChangeLogo(false);
                  onSelectedChange(false);
                }}
                to={`/${data.name}`}
                className={({ isActive }) => (isActive ? 'active ' : 'active')}
              >
                {data.name}
              </HeaderLinks>
            </div>
          );
        })}
        <div className='p-4'>
          <LoginButton
            onClick={() => {
              onSelectedChangeLogo(false);
              onSelectedChange(false);
            }}
          >
            <NavLink
              className={({ isActive }) =>
                isActive ? 'on text-white ' : 'text-white'
              }
              to={`/login`}
            >
              Login
            </NavLink>
          </LoginButton>
        </div>
      </SideBar>
    </div>,
    document.getElementById('sidebar') as HTMLElement
  );
};

export default Sidebar;

const SideBar = styled.div`
  height: 150vh;
  width: 70vw;
  background-color: #2e3d49;
  position: absolute;
  top: 0;
  z-index: 102;
`;

const LogoText = styled.p`
  text-transform: uppercase;
  margin-left: 1rem;
  font-size: 1.75rem;
  font-family: 'Nanum Brush Script', cursive;
  font-weight: 700;
  background-color: #1c262f;
`;

const HeaderLinks = styled(NavLink)`
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0;
  border-radius: 0;
  transition: opacity 0.3s ease;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  width: 100%;
  padding: 1.5rem 1.5rem 1.5rem;
  background-color: transparent;
  border: 0.125rem solid transparent;
  text-transform: uppercase;
  &:hover {
    color: #ca4557;
  }
`;
