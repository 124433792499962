export enum ActionType { 
    SEARCH_NEWS = 'search_news',
    SEARCH_NEWS_SUCCESS = 'search_news_success',
    SEARCH_NEWS_ERROR = 'search_news_error',
    SEARCH_INDV_NEWS_SUCCESS = 'search-indv_news_success',
    LOGIN_ERROR='login_error',
    LOGIN_SUCCESS='login_success',
    LOGIN='login',
    CREATE_USER='create_user',
    CREATE_USER_SUCCESS='create_user_success',
    CREATE_USER_ERROR='create_user_error',
    CURRENT_USER='current_user',
    CURRENT_USER_SUCCESS='current_user_success',
    CURRENT_USER_ERROR='current_user_error',
    LOGOUT_ERROR='logout_error',
    LOGOUT_SUCCESS='logout_success',
    LOGOUT='logout',
    UPDATE_USER_SUCCESS='update_user_success',
    UPDATE_USER='update_user',
    UPDATE_USER_ERROR='update_user_error',
    ALL_USERS_SUCCESS='all_users_success',
    ALL_USERS='all_users',
    ALL_USERS_ERROR='all_users_error',
    ALL_USERS_INDV_SUCCESS='all_users_indv_success',
    POST_NEWS='post_news',
    POST_NEWS_SUCCESS='post_news_success',
    POST_NEWS_ERROR='post_news_error',
    DELETE_NEWS_SUCCESS='delete_news_success',
    DELETE_NEWS='delete_news',
    DELETE_NEWS_ERROR='delete_news_error',
    UPDATE_NEWS='update_news',
    UPDATE_NEWS_SUCCESS='update_news_success',
    UPDATE_NEWS_ERROR='update_news_error',
    POST_PROJECT='post_project',
    POST_PROJECT_SUCCESS='post_project_success',
    POST_PROJECT_ERROR='post_project_error',
    SEARCH_PROJECTS = 'search_projects',
    SEARCH_PROJECTS_SUCCESS = 'search_projects_success',
    SEARCH_PROJECTS_ERROR = 'search_projects_error',
    SEARCH_INDV_PROJECT_SUCCESS = 'search-indv_project_success',
    DELETE_PROJECT_SUCCESS='delete_project_success',
    DELETE_PROJECT='delete_project',
    DELETE_PROJECT_ERROR='delete_project_error',
    UPDATE_PROJECT='update_project',
    UPDATE_PROJECT_SUCCESS='update_project_success',
    UPDATE_PROJECT_ERROR='update_project_error',
    POST_PUBLICATION='post_publication',
    POST_PUBLICATION_SUCCESS='post_publication_success',
    POST_PUBLICATION_ERROR='post_publication_error',
    SEARCH_PUBLICATIONS = 'search_publications',
    SEARCH_PUBLICATIONS_SUCCESS = 'search_publications_success',
    SEARCH_PUBLICATIONS_ERROR = 'search_publications_error',
    SEARCH_INDV_PUBLICATION_SUCCESS = 'search-indv_publication_success',
    DELETE_PUBLICATION_SUCCESS='delete_publication_success',
    DELETE_PUBLICATION='delete_publication',
    DELETE_PUBLICATION_ERROR='delete_publication_error',
    UPDATE_PUBLICATION='update_publication',
    UPDATE_PUBLICATION_SUCCESS='update_publication_success',
    UPDATE_PUBLICATION_ERROR='update_publication_error',
    DELETE_USER_ERROR='delete_user_error',
    DELETE_USER_SUCCESS='delete_user_success',
    DELETE_USER='delete_user',
    POST_EDUCATION_SUCCESS='post_education_success',
    POST_EDUCATION='post_education',
    POST_EDUCATION_ERROR='post_education_error',
    DELETE_EDUCATION_ERROR='delete_education_error',
    DELETE_EDUCATION='delete_education',
    DELETE_EDUCATION_SUCCESS='delete_education_success',
    SEARCH_EDUCATION='search_education',
    SEARCH_EDUCATION_SUCCESS='search_education_success',
    SEARCH_EDUCATION_ERROR='search_education_error',
    UPDATE_EDUCATION='update_education',
    UPDATE_EDUCATION_SUCCESS='update_education_success',
    UPDATE_EDUCATION_ERROR='update_education_error',
    POST_WORKSHOP_SUCCESS='post_WORKSHOP_success',
    POST_WORKSHOP='post_WORKSHOP',
    POST_WORKSHOP_ERROR='post_WORKSHOP_error',
    DELETE_WORKSHOP_ERROR='delete_WORKSHOP_error',
    DELETE_WORKSHOP='delete_WORKSHOP',
    DELETE_WORKSHOP_SUCCESS='delete_WORKSHOP_success',
    SEARCH_WORKSHOP='search_WORKSHOP',
    SEARCH_WORKSHOP_SUCCESS='search_WORKSHOP_success',
    SEARCH_WORKSHOP_ERROR='search_WORKSHOP_error',
    UPDATE_WORKSHOP='update_WORKSHOP',
    UPDATE_WORKSHOP_SUCCESS='update_WORKSHOP_success',
    UPDATE_WORKSHOP_ERROR='update_WORKSHOP_error',
    POST_APPLY_JOB_SUCCESS='post_APPLY_JOB_success',
    POST_APPLY_JOB='post_APPLY_JOB',
    POST_APPLY_JOB_ERROR='post_APPLY_JOB_error',
    SEARCH_APPLY_JOB='search_APPLY_JOB',
    SEARCH_APPLY_JOB_SUCCESS='search_APPLY_JOB_success',
    SEARCH_APPLY_JOB_ERROR='search_APPLY_JOB_error',
    POST_CONTACTUS_SUCCESS='post_CONTACTUS_success',
    POST_CONTACTUS='post_CONTACTUS',
    POST_CONTACTUS_ERROR='post_CONTACTUS_error',
    SEARCH_CONTACTUS='search_CONTACTUS',
    SEARCH_CONTACTUS_SUCCESS='search_CONTACTUS_success',
    SEARCH_CONTACTUS_ERROR='search_CONTACTUS_error',
    POST_JOB_SUCCESS='post_JOB_success',
    POST_JOB='post_JOB',
    POST_JOB_ERROR='post_JOB_error',
    SEARCH_JOBS='search_JOBS',
    SEARCH_JOBS_SUCCESS='search_JOBS_success',
    SEARCH_JOBS_ERROR='search_JOBS_error',
    
    POST_NOTES_SUCCESS='post_NOTES_success',
    POST_NOTES='post_NOTES',
    POST_NOTES_ERROR='post_NOTES_error',
    SEARCH_NOTES='search_NOTESS',
    SEARCH_NOTES_SUCCESS='search_NOTES_success',
    SEARCH_NOTES_ERROR='search_NOTES_error',
    SEARCH_SEARCH='search_SEARCHS',
    SEARCH_SEARCH_SUCCESS='search_SEARCH_success',
    SEARCH_SEARCH_ERROR='search_SEARCH_error',
};
