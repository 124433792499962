import { ActionType } from '../action-types';
import { Action, ProjectData } from '../actions';

interface ProjectState {
    loading: boolean;
    error: string | null;
    data: ProjectData[];
    indvData?: ProjectData;
};

const initialState = {
    loading: false,
    error: null,
    data: [],
    indvData: {
        title: '',
        body: '',
        createdAt: new Date(),
        projectImage: '',
        projectLink: ''
    }
};



const reducer = (state: ProjectState = initialState, action: Action): ProjectState => {
    switch (action.type) {
        case ActionType.SEARCH_PROJECTS:
            return { loading: true, error: null, data: [] }
        case ActionType.SEARCH_PROJECTS_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.SEARCH_PROJECTS_ERROR:
            return { loading: false, error: action.payload, data: [] }
        case ActionType.SEARCH_INDV_PROJECT_SUCCESS:
            return { loading: false, error: null, data: [], indvData: action.payload }
        case ActionType.POST_PROJECT:
            return { loading: true, error: null, data: [] }
        case ActionType.POST_PROJECT_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.POST_PROJECT_ERROR:
            return { loading: false, error: action.payload, data: [] }
        case ActionType.DELETE_PROJECT:
            return { loading: true, error: null, data: [] }
        case ActionType.DELETE_PROJECT_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.DELETE_PROJECT_ERROR:
            return { loading: false, error: action.payload, data: []}
        case ActionType.UPDATE_PROJECT:
            return { loading: true, error: null, data: initialState.data };
        case ActionType.UPDATE_PROJECT_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.UPDATE_PROJECT_ERROR:
            return { loading: false, error: action.payload, data: [] } 
        default:
            return state;
    };
};

export default reducer;