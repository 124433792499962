import { Action } from './../actions/index';
import { UserData } from '../actions';
import { ActionType } from './../action-types/index';

interface AllUsersState {
    loading: Boolean;
    error: string | null;
    data: UserData[];
    indvData?: UserData;
};

const initialState = {
    loading: false,
    error: null,
    data: [],
    indvUserData: {
        role: 'student',
        email: '',
        name: '',
        _id: '',
        about: '',
        skills: [],
        expertise: [],
        hobbies: [],
        languages: [],
        linkedin: '', 
        twitter: '', 
        link: '', 
        instagram: '', 
        facebook: '',
        photos: [''],
        profileImage: 'https://avatars.githubusercontent.com/u/60587294?s=88&v=4',
    }
};


const reducer = (state: AllUsersState = initialState, action: Action): AllUsersState => {
    switch (action.type) {
        case ActionType.ALL_USERS:
            return { loading: true, error: null, data: initialState.data }
        case ActionType.ALL_USERS_SUCCESS:
            return { loading: false, error: null, data: action.payload}
        case ActionType.ALL_USERS_ERROR:
            return { loading: false, error: action.payload, data: [] }
        case ActionType.ALL_USERS_INDV_SUCCESS:
            return { loading: false, error: null, data: [], indvData: action.payload }
        default:
            return state;
    };
};

export default reducer;