import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { useState, useEffect, useRef } from "react";
import { OnChange } from "react-final-form-listeners";
import { useActions } from "../hooks/useActions";
import { useNavigate, useParams } from "react-router-dom";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useSockets } from "../context/socket.context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface UserData {
  userData: {
    _id: string;
  };
}

const UpdatePublication: React.FC<UserData> = ({ userData: { _id } }) => {
  const { postPublication, updatePublicationDetails, searchIndvPublication } =
    useActions();
  const { id } = useParams();
  const { data, indvData, error, loading } = useTypedSelector(
    (state) => state.publicationReducer
  );
  const [err, setErr] = useState(false);
  const [reset, setReset] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [preview, setPreview] = useState(false);
  const navigate = useNavigate();
  const { socket } = useSockets();
  const [previewSource, setPreviewSource] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<FileReader>();
  const ref = useRef(null);
  const [publicationData, setPublicationData] = useState<any | undefined>();

  const required = (value: string) => (value ? undefined : 'Required')
  useEffect(() => {
    if (id) {
      searchIndvPublication(id);
    }
  }, []);

  useEffect(() => {
    if (id && !loading && indvData && indvData["title"] !== "") {
      setPublicationData(indvData);
    }
  }, [publicationData]);

  useEffect(() => {
    if (error !== null && err) {
      toast.error(
        "Seems like there was an error. Please check your fields and make sure they are unique!"
      );
      setLoader(false)
    } else if (
      (((data.length !== 0 || indvData?.title) &&
      err &&
      error === null && loader)) || (data[0] && data[0].authors && loader)
    ) {
      toast.success("Publication Posted,redirecting to dashboard..");
      setTimeout(() => {
        navigate("/dashboard");
        socket.emit("refreshDashboard");
      }, 2000);
    } 
  }, [err, error, loading]);

  const previewFile = (file: any) => {
    if((file && !id) || preview) {
      const reader = new FileReader();
      setSelectedFile(reader);
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPreviewSource(reader.result);
      }
    };
  };

  return (
    <div>
      <div style={{ cursor: 'pointer'}} className="fs-4 my-2 fw-bold d-flex align-items-center">
        <i onClick={() => navigate(-1)} className="fas fa-long-arrow-alt-left fs-2 ms-2"></i>
      </div>
      <Form
        initialValues={{
          title: id && indvData?.title,
          authors: id && indvData?.authors,
          citations: id && indvData?.citations,
          publicationLink: id && indvData?.publicationLink,
          publicationImage: id && indvData?.publicationImage,
          publicationTag: id && indvData?.publicationTag,
        }}
        onSubmit={({
          title,
          authors,
          publicationTag='Health',
          publicationLink,
          publicationImage,
          citations,
        }) => {
          if (id) {
            console.log('previewSource')
            updatePublicationDetails(
              id,
              title,
              authors,
              publicationTag,
              publicationLink,
              (publicationImage = indvData && indvData?.publicationImage || previewSource),
              citations
            );
          } else {
            postPublication(
              title,
              authors,
              publicationTag,
              publicationLink,
              _id,
              (publicationImage = previewSource),
              citations
            );
          }
        }}
      >
        {({ handleSubmit, form, pristine }) => {
          return (
            <form
              ref={ref}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
                setLoader(true)
              }}
            >
              <PublicationPostCard>
                <PublicationTitle>Publications</PublicationTitle>
                <div className="image-details row">
                  <div className="left col-12 col-md-3 mb-3 d-flex justify-content-center">
                    <Field name="publicationImage" validate={required}>
                      {({ meta, input: { value, onChange,  ...input } }) => {
                        return (
                          <UserImageContainer>
                            {/* <InputBox> */}
                            <InputFileUpload
                              onChange={({ target }) =>
                                onChange(
                                  target && target.files && target.files[0]
                                )
                              }
                              {...input}
                              style={{ cursor: "pointer" }}
                              type="file"
                              name="publicationImage"
                              className=""
                            />
                            <div className="image">
                              {previewSource ? (
                                <img
                                  src={previewSource}
                                  alt="chosen"
                                  // style={{ height: '300px' }}
                                />
                              ) : ( id && indvData?.publicationImage ? 
                                <img
                                  src={indvData?.publicationImage}
                                  alt={indvData?.title}
                                /> : <i className="fas fa-upload mt-5 fs-1"></i>
                              )}

                            {indvData?.publicationImage ?  <i className="fas fa-plus w-100  fs-4 text-end"></i> : ''}
                            </div>
                            {meta.error && meta.touched && !previewSource && <span className="text-danger">{meta.error}</span>}
                            {/* </InputBox> */}
                           
                          </UserImageContainer>
                        );
                      }}
                    </Field>
                    <OnChange name="publicationImage" >
                      {(value, previous) => {
                        if(value) {
                          setPreview(true);
                          previewFile(value);
                        }
                        if (!previous) {
                          previewFile(value);
                        
                        }
                      }}
                    </OnChange>
                 
                  </div>
                  <div className="right col-12 col-md-9">
                    <label htmlFor="title">Publication Title</label>
                    <Field name="title" validate={required}>
                      {({ input, meta }) => {
                        return <div>
                          <InputBox id="title" type="text" {...input} />
                          {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                        </div>
                      }}
                    </Field>
                    <label htmlFor="authors">Publication Authors</label>
                    <Field name="authors" validate={required}>
                      {({ input, meta }) => {
                        return <div>
                           <InputBox id="authors" type="text" {...input} />
                          {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                        </div>
                        ;
                      }}
                    </Field>
                    <label htmlFor="citations">Citations</label>
                    <Field name="citations" validate={required}>
                      {({ input, meta }) => {
                        return (
                          <div>
                            <InputBox id="citations" type="text" {...input} />
                            {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                          </div>
                        );
                      }}
                    </Field>
                    {/* <label htmlFor="publicationTag">Publication Tag</label> */}
                    <Field name="publicationTag">
                      {({ input }) => {
                        return (
                          <div className="mb-2">
                            <label className={"mb-2"} htmlFor="jobType">
                              Publication Tag
                            </label>
                            <select
                              {...input}
                              id="publicationTag"
                              style={{ height: "3rem" }}
                              className="form-select"
                              aria-label="Default select"
                            >
                               <option value="Others">Others</option>
                              <option value="Health">Health</option>
                              <option value="Comfort">Comfort</option>
                              <option value="Energy">Energy</option>
                              <option value="Water">Water</option>
                              <option value="Patents">Patents</option>
                            </select>
                          </div>
                        );
                      }}
                    </Field>
                    
                    <label htmlFor="publicationLink">Publication Link</label>
                    <Field name="publicationLink" validate={required}>
                      {({ input, meta }) => {
                        
                        return (
                          <div>
                            <InputBox
                            id="publicationLink"
                            type="text"
                            {...input}
                          />
                            {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                          </div>
                        );
                        
                      }}
                    </Field>
                  </div>
                </div>
              </PublicationPostCard>
              <div className="buttons d-flex justify-content-end mb-5">
                <NewsButtons
                  type="submit"
                  disabled={pristine}
                  onClick={() => {
                    setErr(true);
                  }}
                >
                {loader && !error ? 'Submitting...' : 'Submit'}
                </NewsButtons>
              </div>
            </form>
          );
        }}
      </Form>
      <ToastContainer />
    </div>
  );
};

export default UpdatePublication;

export const InputFileUpload = styled.input`
  position: absolute;
  width: 120px;
  height: 140px;
  opacity: 0;
  z-index: 2;
`;

export const UserImageContainer = styled.div`
  border-radius: 120px;
  width: 120px;
  height: 120px;
  opacity: 0.7;

  & img {
    border-radius: 120px;
    width: 120px;
    height: 120px;
  }

  & i {
    position: relative;
    /* position: absolute; */
    bottom: 0.5rem;
  }
`;

export const PublicationPostCard = styled.div`
  background-color: #fff;
  border: 1px solid #dbe2e8;
  padding: 20px;
  margin-bottom: 20px;
`;

export const PublicationTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5rem;
  color: "#2e3d49";
  /* margin-bottom: 1rem */
`;

export const InputBox = styled.input`
  color: #2e3d49;
  padding: 10px 5px;
  border: 1px solid #dbe2e8;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 0.875rem;
  height: 3rem;
  outline: none;

  &:active,
  :focus {
    border: 1px solid var(--primary);
  }
`;

export const NewsButtons = styled.button`
  height: 3rem;
  padding: 0 1rem;
  background: var(--primary);
  border: transparent;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  letter-spacing: 1.5px;
  line-height: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
`;

export const TextareaBox = styled.textarea`
  color: #2e3d49;
  padding: 10px 5px;
  border: 1px solid #dbe2e8;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 0.875rem;
  min-height: 10rem;
  max-height: 15rem;
  overflow-y: scroll;
  outline: none;
`;
