import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

// created components
import './global.css';
import { store } from './states';


const Root: React.FC = () => {
    return <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>
}

ReactDOM.render(<Root />, document.getElementById('root'));
