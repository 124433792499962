import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import DashboardJobCard from "../components/dashboard/DashboardJobCard";
import { useActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";

interface userData {
    userData: {
      role: string;
      email: string;
      name: string;
      _id: string;
      news?: any[];
      projects?: any[];
      publications?: any[];
      education?: any[];
      workshops?: any[];
      jobs?: any[];
      notes?: any[];
    },
  }
  

const SeeAllDashboardJobs: React.FC<userData> = ({ userData }) => {
    const { getAllUsers } = useActions();
    const { id } = useParams();
    const { data, loading } = useTypedSelector((state) => state.allUsersReducer);
    const [allUsers, setAllUsers] = useState<any[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
      getAllUsers();
    }, []);
  
    useEffect(() => {
      if (!loading && data.length !== 0) {
        setAllUsers(data);
      }
    }, [data]);
    return <div>
        <div style={{ cursor: 'pointer' }} className="fs-4 my-2 fw-bold d-flex align-items-center">
          <i onClick={() => navigate(-1)} className="fas fa-long-arrow-alt-left fs-2 ms-2"></i>
        </div>
         <DashboardListContainer className="row d-flex m-0 justify-content-center justify-content-xl-start">
        <div className="row">
          {[...(userData.jobs ?? [])].map((data, index) => {
            return (
              <div
                key={index}
                className="col-md-6 col-lg-4 col-xl-3 mt-4 mt-md-0"
              >
                <DashboardJobCard jobData={data} />
              </div>
            );

          })}
        </div>
      </DashboardListContainer>
    </div>
};

export default SeeAllDashboardJobs;


const DashboardListContainer = styled.div`
  margin-top: 4rem;
  @media (max-width: 577px) {
    margin-top: 0.5rem;
  }
`;
