import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { useState, useEffect, useRef } from "react";
import { OnChange } from "react-final-form-listeners";
import { useActions } from "../hooks/useActions";
import { useNavigate, useParams } from "react-router-dom";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useSockets } from "../context/socket.context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface UserData {
  userData: {
    _id: string;
  };
}


const ChangePassword: React.FC<UserData> = ({ userData: { _id } }) => {

  const { id } = useParams();
  const { data, indvData, error, loading } = useTypedSelector(
    (state) => state.userReducer
  );
  const { updatePasswordDetails } = useActions();

  const [err, setErr] = useState(false);
  const [reset, setReset] = useState<any>();
  const [loader, setLoader] = useState(false);
  const [preview, setPreview] = useState(false);
  const navigate = useNavigate();
  const { socket } = useSockets();
  const [previewSource, setPreviewSource] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<FileReader>();
  const ref = useRef(null);
  const [publicationData, setPublicationData] = useState<any | undefined>();

  const required = (value: string) => (value ? undefined : 'Required')


  // useEffect(() => {
  //   if (id && !loading && indvData && indvData["title"] !== "") {
  //     setPublicationData(indvData);
  //   }
  // }, [publicationData]);

  useEffect(() => {
    if (error !== null && err) {
      toast.error(
        "Seems like there was an error. Please check your fields and make sure they are unique!"
      );
      setLoader(false)
    } else if (
      (((data.length !== 0 || indvData?.title) &&
      err &&
      error === null && loader)) || (data[0] && data[0].authors && loader)
    ) {
      toast.success("Password changed,redirecting to dashboard..");
      setTimeout(() => {
        navigate("/dashboard");
        socket.emit("refreshDashboard");
      }, 2000);
    } 
  }, [err, error, loading]);

  return (
    <div className="">
       <div style={{ cursor: 'pointer'}} className="fs-4 my-2 p-0 fw-bold d-flex align-items-center">
        <i onClick={() => navigate(-1)} className="fas fa-long-arrow-alt-left fs-2 ms-2"></i>
      </div>
      <Form
        onSubmit={({
          passwordCurrent,
          password,
          passwordConfirm,
        }) => {
          updatePasswordDetails(
            passwordCurrent,
            password,
            passwordConfirm,
          );
        }}
      >
        {({ handleSubmit, form, pristine }) => {
          return (
            <form
              ref={ref}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
                setLoader(true)
              }}
            >
              <PublicationPostCard className="mb-5">
                <PublicationTitle>Change Password</PublicationTitle>
                <div className="image-details row">
                  <div className="right col-12">
                  <label htmlFor="passwordCurrent">Current Password</label>
                    <Field name="passwordCurrent" validate={required}>
                      {({ input, meta }) => {
                        return <div>
                          <InputBox id="passwordCurrent" type="password" {...input} />
                          {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                        </div>
                      }}
                    </Field>
                    <label htmlFor="password">New Password</label>
                    <Field name="password" validate={required}>
                      {({ input, meta }) => {
                        return <div>
                          <InputBox id="password" type="password" {...input} />
                          {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                        </div>
                      }}
                    </Field>
                    <label htmlFor="passwordConfirm">Confirm Password</label>
                    <Field name="passwordConfirm" validate={required}>
                      {({ input, meta }) => {
                        return <div>
                           <InputBox id="passwordConfirm" type="password" {...input} />
                          {meta.error && meta.touched && <span className="text-danger">{meta.error}</span>}
                        </div>
                        ;
                      }}
                    </Field>
                  </div>
                </div>
              </PublicationPostCard>
              <div className="buttons d-flex justify-content-end mb-5">
                <NewsButtons
                  type="submit"
                  disabled={pristine}
                  onClick={() => {
                    setErr(true);
                  }}
                >
                {loader && !error ? 'Submitting...' : 'Submit'}
                </NewsButtons>
              </div>
            </form>
          );
        }}
      </Form>
      <ToastContainer />
    </div>
  );
};

export default ChangePassword;

export const InputFileUpload = styled.input`
  position: absolute;
  width: 120px;
  height: 140px;
  opacity: 0;
  z-index: 2;
`;

export const UserImageContainer = styled.div`
  border-radius: 120px;
  width: 120px;
  height: 120px;
  opacity: 0.7;

  & img {
    border-radius: 120px;
    width: 120px;
    height: 120px;
  }

  & i {
    position: relative;
    /* position: absolute; */
    bottom: 0.5rem;
  }
`;

export const PublicationPostCard = styled.div`
  background-color: #fff;
  border: 1px solid #dbe2e8;
  padding: 20px;
  margin-bottom: 20px;
`;

export const PublicationTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5rem;
  color: "#2e3d49";
  /* margin-bottom: 1rem */
`;

export const InputBox = styled.input`
  color: #2e3d49;
  padding: 10px 5px;
  border: 1px solid #dbe2e8;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 0.875rem;
  height: 3rem;
  outline: none;

  &:active,
  :focus {
    border: 1px solid var(--primary);
  }
`;

export const NewsButtons = styled.button`
  height: 3rem;
  padding: 0 1rem;
  background: var(--primary);
  border: transparent;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  letter-spacing: 1.5px;
  line-height: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
`;

export const TextareaBox = styled.textarea`
  color: #2e3d49;
  padding: 10px 5px;
  border: 1px solid #dbe2e8;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 0.875rem;
  min-height: 10rem;
  max-height: 15rem;
  overflow-y: scroll;
  outline: none;
`;
