import { ActionType } from '../action-types';
import { Action, PublicationData } from '../actions';

interface PublicationState {
    loading: Boolean;
    error: string | null;
    data: any;
    indvData?: PublicationData;
};

const initialState = {
    loading: false,
    error: null,
    data: [],
    indvData: {
        title: '',
        authors: '',
        createdAt: new Date(),
        publicationLink: '',
        citations: '',
        publicationImage: '',
        publicationTag: '',
    }
};



const reducer = (state: PublicationState = initialState, action: Action): PublicationState => {
    switch (action.type) {
        case ActionType.SEARCH_PUBLICATIONS:
            return { loading: true, error: null, data: [] }
        case ActionType.SEARCH_PUBLICATIONS_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.SEARCH_PUBLICATIONS_ERROR:
            return { loading: false, error: action.payload, data: [] }
        case ActionType.SEARCH_INDV_PUBLICATION_SUCCESS:
            return { loading: false, error: null, data: [], indvData: action.payload }
        case ActionType.POST_PUBLICATION:
            return { loading: true, error: null, data: [] }
        case ActionType.POST_PUBLICATION_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.POST_PUBLICATION_ERROR:
            return { loading: false, error: action.payload, data: [] }
        case ActionType.DELETE_PUBLICATION:
            return { loading: true, error: null, data: [] }
        case ActionType.DELETE_PUBLICATION_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.DELETE_PUBLICATION_ERROR:
            return { loading: false, error: action.payload, data: []}
        case ActionType.UPDATE_PUBLICATION:
            return { loading: true, error: null, data: initialState.data };
        case ActionType.UPDATE_PUBLICATION_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        case ActionType.UPDATE_PUBLICATION_ERROR:
            return { loading: false, error: action.payload, data: [] } 
        default:
            return state;
    };
};

export default reducer;