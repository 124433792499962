import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { useActions } from "../../hooks/useActions";
import { useSockets } from "../../context/socket.context";

export interface EducationData {
  educationData: {
    id: string;
    title: string;
    body: string;
    createdAt: Date;
    department: string;
    instructorImage: string;
  };
}

const DashboardEducationCard: React.FC<EducationData> = ({
    educationData,
}) => {
  const { deletePublication } = useActions();
  const { socket } = useSockets();

  return (
    <div className="mb-3">
      <Card>
        <article>
          <div className="row m-0">
            <div className="p-3">
              <h3
                className=""
                style={{ fontSize: ".875rem", color: "#bcc0c3" }}
              >
                {educationData &&
                  moment(educationData.createdAt).format("llll")}
              </h3>
              <h3 className="fs-5 ">
                {educationData && educationData.title &&  educationData.title.length < 25
                  ? educationData.title
                  : `${educationData.title.substr(0, 30)}...`}
              </h3>
              <p
                className=""
                style={{
                  // height: '10rem',
                  fontStyle: "italic",
                  color: "#525c65",
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  lineHeight: "1.5rem",
                }}
              >
                {educationData && educationData.body.length < 25
                  ? educationData.body
                  : `${educationData.body.substr(0, 30)}...`}
              </p>
              <div className="d-flex justify-content-between">
                <Link
                  to={`/dashboard/update-education/${educationData.id}`}
                >
                  <div className="p-2 bg-light rounded-3">
                    <i className="fas fa-pencil-alt"></i>
                  </div>
                </Link>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    socket.emit("deletePublication");
                    deletePublication(educationData.id);
                  }}
                  className="p-2 bg-light rounded-3"
                >
                  <i className="fas fa-trash"></i>
                </div>
              </div>
            </div>
          </div>
        </article>
      </Card>
    </div>
  );
};

export default DashboardEducationCard;

const Card = styled.div`
  background-color: #ffffff;
  box-shadow: var(--shadow-1);
  border-radius: 1.375rem;
  height: 12rem;
`;
