import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { useState, useEffect, useRef } from "react";
import { OnChange } from "react-final-form-listeners";
import { useActions } from "../hooks/useActions";
import { useNavigate, useParams } from "react-router-dom";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useSockets } from "../context/socket.context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDEditor from "@uiw/react-md-editor";

interface UserData {
  userData: {
    _id: string;
  };
}

const UpdateNotes: React.FC<UserData> = ({ userData: { _id } }) => {
  const { postNotes } =
    useActions();
  const { id } = useParams();
  const { data, error, loading } = useTypedSelector(
    (state) => state.notesReducer
  );
  const [err, setErr] = useState(false);
  const [loader, setLoader] = useState(false);
  const [preview, setPreview] = useState(false);
  const navigate = useNavigate();
  const { socket } = useSockets();
  const [previewSource, setPreviewSource] = useState<any>("");
  const [selectedFile, setSelectedFile] = useState<FileReader>();
  const ref = useRef(null);
  const [WorkshopData, setWorkshopData] = useState<any | undefined>();

  const required = (value: string) => (value ? undefined : 'Required')
//   useEffect(() => {
//     if (id) {
//       searchIndvPublication(id);
//     }
//   }, []);

//   useEffect(() => {
//     if (id && !loading && indvData && indvData["title"] !== "") {
//       setPublicationData(indvData);
//     }
//   }, [publicationData]);

  useEffect(() => {
    if (error !== null && err) {
      toast.error(
        "Seems like there was an error. Please check your fields and make sure they are unique!"
      );
      setLoader(false)
    } else if (
      (((data.length !== 0) &&
      err &&
      error === null && loader)) || (data[0] && loader)
    ) {
      toast.success("Notes Posted,redirecting to dashboard..");
      setTimeout(() => {
        navigate("/dashboard");
        socket.emit("refreshDashboard");
      }, 2000);
    } 
  }, [err, error, loading]);



  return (
    <div>
      <div style={{ cursor: 'pointer'}} className="fs-4 my-2 fw-bold d-flex align-items-center">
        <i onClick={() => navigate(-1)} className="fas fa-long-arrow-alt-left fs-2 ms-2"></i>
      </div>
      <Form
        // initialValues={{
        //   title: id && indvData?.title,
        //   authors: id && indvData?.authors,
        //   citations: id && indvData?.citations,
        //   publicationLink: id && indvData?.publicationLink,
        //   publicationImage: id && indvData?.publicationImage,
        // }}
        onSubmit={({
          title,
        }) => {
        //   if (id) {
        //     updatePublicationDetails(
        //       id,
        //       title,
        //       authors,
        //       publicationLink,
        //       (publicationImage = previewSource),
        //       citations
        //     );
        //   } else {
            postNotes(
              title,
              _id,
            );
        //   }
        }}
      >
        {({ handleSubmit, form, pristine }) => {
          return (
            <form
              ref={ref}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
                setLoader(true)
              }}
            >
              <WorkshopPostCard>
                <WorkshopTitle>Notes</WorkshopTitle>
                <div className="image-details row">
                  
                  <div className="right col-12">
                    {/* <label htmlFor="title">Notes Title</label> */}
                    <Field name="title">
                  {({ input, meta }) => {
                    return (
                      <div>
                        <MDEditor height={350} {...input} />
                        {meta.error && meta.touched && (
                          <span className="text-danger">{meta.error}</span>
                        )}
                      </div>
                    );
                  }}
                </Field>
                  
                  </div>
                </div>
              
              </WorkshopPostCard>
              <div className="buttons d-flex justify-content-end mb-5">
                <WorkshopButton
                  type="submit"
                  disabled={pristine}
                  onClick={() => {
                    setErr(true);
                  }}
                >
                {loader && !error ? 'Submitting...' : 'Submit'}
                </WorkshopButton>
              </div>
            </form>
          );
        }}
      </Form>
      <ToastContainer />
    </div>
  );
};

export default UpdateNotes;

export const InputFileUpload = styled.input`
  position: absolute;
  width: 120px;
  height: 140px;
  opacity: 0;
  z-index: 2;
`;

export const UserImageContainer = styled.div`
  border-radius: 120px;
  width: 120px;
  height: 120px;
  opacity: 0.7;

  & img {
    border-radius: 120px;
    width: 120px;
    height: 120px;
  }

  & i {
    position: relative;
    /* position: absolute; */
    bottom: 0.5rem;
  }
`;

export const WorkshopPostCard = styled.div`
  background-color: #fff;
  border: 1px solid #dbe2e8;
  padding: 20px;
  margin-bottom: 20px;
`;

export const WorkshopTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 2.5rem;
  color: "#2e3d49";
  /* margin-bottom: 1rem */
`;

export const InputBox = styled.input`
  color: #2e3d49;
  padding: 10px 5px;
  border: 1px solid #dbe2e8;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 0.875rem;
  height: 3rem;
  outline: none;

  &:active,
  :focus {
    border: 1px solid var(--primary);
  }
`;

export const WorkshopButton = styled.button`
  height: 3rem;
  padding: 0 1rem;
  background: var(--primary);
  border: transparent;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  letter-spacing: 1.5px;
  line-height: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
`;

export const TextareaBox = styled.textarea`
  color: #2e3d49;
  padding: 10px 5px;
  border: 1px solid #dbe2e8;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 0.875rem;
  min-height: 10rem;
  max-height: 15rem;
  overflow-y: scroll;
  outline: none;
`;
